import { URLS } from '../../../consts/endpoints';
import { postData } from '../../../utils/http';
import showSnackbar from '../../../utils/showSnackbar';
import RESPONSE_CODES from '../../../consts/responseCodes';

const terminateUser = async ({ values, user, connectClientId }) => {
  const mappedData = mapData({ values, user, connectClientId });

  const response = await postData({
    url: `${URLS.TERMINATE_USER}`,
    data: mappedData
  });

  if (response.status === RESPONSE_CODES.SUCCESS) {
    showSnackbar({
      intlTextKey: 'MESSAGE.TERMINATE_USER',
      messageType: 'success'
    });
    return true;
  }

  return false;
};

export default terminateUser;

const mapData = ({ values, user, connectClientId }) => {
  const mappedValues = Object.keys(values).reduce((arr, el) => {
    const val = values[el];

    return [
      ...arr,
      {
        ...val,
        id: el
      }
    ];
  }, []);

  return {
    subscriptions: mappedValues,
    telepo_user_id: user?.user_id,
    telepo_domain: connectClientId
  };
};
