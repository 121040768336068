import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { actions as sagaActions, OPEN_DATALIMIT } from './types';
import { actions as reduxActions } from '../reducers';
import mapDataToEdit from './mapDataToEdit';
import { isArray, isDefined, isUndefined } from '../../../utils/isDefined';
import { getData } from '../../../utils/http';
import { JSONAPI_URLS, URLS } from '../../../consts/endpoints';
import urlCreator from '../../../utils/urlCreator';
import Optional from '../../../utils/optional';

export function openEditDatalimit({
  datalimit,
  fromUserDetails,
  fromLookup,
  fromRightPanel,
  lookupNumber,
  datalimitId
}) {
  return {
    type: sagaActions[OPEN_DATALIMIT],
    datalimit,
    fromUserDetails,
    fromLookup,
    fromRightPanel,
    lookupNumber,
    datalimitId
  };
}

export function* setDatalimit({
  datalimit,
  fromUserDetails,
  fromLookup,
  fromRightPanel,
  lookupNumber,
  datalimitId
}) {
  let fetchedDatalimit;

  if (isDefined(datalimitId)) {
    const queryParams = {
      include: ['client']
    };
    try {
      const { data } = yield call(
        getData,
        urlCreator(`${URLS.DATA_LIMITS}/${datalimitId}.json`, queryParams)
      );

      fetchedDatalimit = data;
    } catch {
      fetchedDatalimit = {};
    }
  }

  const { mappedDatalimit, clientId } = yield call(
    mapDataToEdit,
    isDefined(datalimitId) ? fetchedDatalimit : datalimit
  );

  const numberToSearch = mappedDatalimit?.numbers.split()[0];

  const simcardQueryParams = {
    filter: { msisdn: numberToSearch }
  };

  const { data: foundedSimcards } = yield call(
    getData,
    urlCreator(JSONAPI_URLS.SIM_CARDS, simcardQueryParams)
  );

  const isTDC = checkNetwork(foundedSimcards);

  yield put(
    reduxActions.openEditDatalimit({
      datalimit: mappedDatalimit,
      fromUserDetails,
      fromLookup,
      fromRightPanel,
      lookupNumber,
      datalimitId,
      clientId,
      isTDC
    })
  );
}

function* watchSetDatalimit() {
  yield takeLatest(sagaActions[OPEN_DATALIMIT], setDatalimit);
}

export default watchSetDatalimit();

const checkNetwork = simcards => {
  if (!isArray(simcards) || isUndefined(simcards[0])) {
    return false;
  }

  return Optional(simcards[0].mobile_network?.brand)
    .map(brand => brand === 'TDC')
    .or(false);
};
