import { createReduxActionTypes } from '../../../utils/actionFactory';

const SET_DATALIMIT = 'SET_DATALIMIT';
const RESET_DATALIMIT = 'RESET_DATALIMIT';

export const actionTypes = createReduxActionTypes('EDIT_DATALIMIT', [
  SET_DATALIMIT,
  RESET_DATALIMIT
]);

const DEFAULT_STATE = {
  open: false,
  datalimit: {},
  fromUserDetails: false,
  isTDC: false
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_DATALIMIT]: {
      const {
        datalimit,
        fromUserDetails,
        fromLookup,
        fromRightPanel,
        lookupNumber,
        datalimitId,
        clientId,
        isTDC
      } = action;

      return {
        ...state,
        datalimit,
        fromUserDetails,
        open: true,
        fromLookup,
        fromRightPanel,
        lookupNumber,
        datalimitId,
        clientId,
        isTDC
      };
    }
    case actionTypes[RESET_DATALIMIT]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  openEditDatalimit: ({
    datalimit,
    fromUserDetails,
    fromLookup,
    fromRightPanel,
    lookupNumber,
    datalimitId,
    clientId,
    isTDC
  }) => ({
    type: actionTypes[SET_DATALIMIT],
    datalimit,
    fromUserDetails,
    fromLookup,
    fromRightPanel,
    lookupNumber,
    datalimitId,
    clientId,
    isTDC
  }),
  resetEditDatalimit: () => ({ type: actionTypes[RESET_DATALIMIT] })
};
