const styles = theme => ({
  root: {
    position: 'relative',
    minWidth: '400px',
    height: 'fit-content',
    margin: '5px'
  },
  title: {
    display: 'flex',
    height: '40px',
    padding: '10px',
    fontSize: '15px',
    fontWeight: 500,
    color: ' #595D6E',
    borderBottom: '1px solid #F2F3F8'
  },
  contentContainer: {
    backgroundColor: '#fff',
    borderRadius: '10px',
    padding: '20px 0',
    display: 'flex',
    justifyContent: 'space-around'
  },
  buttonLabel: {
    width: '100%',
    display: 'flex',
    height: '50px',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '5px',
    padding: '5px 10px',
    '&:hover': { filter: 'brightness(0.98)' }
  },
  icon: {
    width: '42px',
    paddingRight: '10px'
  }
});

export default styles;
