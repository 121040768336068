import { createReduxActionTypes } from '../../../utils/actionFactory';
import Optional from '../../../utils/optional';

const OPEN_DELETE_CLIENT = 'OPEN_DELETE_CLIENT';
const RESET_DELETE_CLIENT = 'RESET_DELETE_CLIENT';

export const actionTypes = createReduxActionTypes('DELETE_CLIENT', [
  OPEN_DELETE_CLIENT,
  RESET_DELETE_CLIENT
]);

const DEFAULT_STATE = {
  open: false,
  client: {},
  usersCount: void 0,
  numberRangesCount: void 0,
  canDelete: false
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[OPEN_DELETE_CLIENT]: {
      const { client, usersCount, numberRangesCount, canDelete } = action;
      return {
        ...state,
        open: true,
        client: Optional(client).or({}),
        usersCount,
        numberRangesCount,
        canDelete
      };
    }
    case actionTypes[RESET_DELETE_CLIENT]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  openDeleteClient: ({ client, usersCount, numberRangesCount, canDelete }) => ({
    type: actionTypes[OPEN_DELETE_CLIENT],
    client,
    usersCount,
    numberRangesCount,
    canDelete
  }),
  closeDeleteClient: () => ({ type: actionTypes[RESET_DELETE_CLIENT] })
};
