import {
  Dialog,
  DialogActions,
  DialogContent,
  withStyles
} from '@material-ui/core';
import React, { useEffect } from 'react';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';

import CustomDialogTitle from '../../partials/customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../../partials/DraggableDialog';
import styles from './styles';
import ActionsButtons from './components/ActionsButtons';
import { actions as reduxActions } from './reducers';
import { translate } from '../../i18n/I18nProvider';
import UserData from './components/UserData';
import Subscription from './components/Subscription';
import { isDefined } from '../../utils/isDefined';

const TerminateUser = ({ open, classes, closeDialog, error }) => {
  useEffect(() => {
    return closeDialog();
  }, [closeDialog]);

  return (
    <>
      <Dialog
        maxWidth="lg"
        classes={{
          paper: classes.root
        }}
        PaperComponent={DraggableDialog}
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            closeDialog();
          }
        }}
      >
        <CustomDialogTitle onCloseClick={closeDialog}>
          {translate('TERMINATE_USER.LABEL')}
        </CustomDialogTitle>
        <DialogContent>
          {isDefined(error) && (
            <div className={classes.errorContainer}>{error}</div>
          )}
          <div className={classes.dialogContent}>
            <UserData />
            <div className={classes.divider}></div>
            <Subscription />
          </div>
        </DialogContent>
        <DialogActions className={classes.footer}>
          <ActionsButtons />
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStatesToProps = ({ terminateUser }) => {
  return {
    ...terminateUser
  };
};

const mapDispatchToProps = {
  closeDialog: reduxActions.closeTerminateUser
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(TerminateUser);
