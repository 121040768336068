import React, { useState, cloneElement, useCallback } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';

import withUserCheck from '../../../utils/withUserCheck';
import { translate } from '../../../i18n/I18nProvider';
import { actions as showHistoryReduxActions } from '../../../modals/showHistory/reducers';
import openDeleteClient from '../../../modals/deleteClient/actions/openDeleteClient';
import { isDefined } from '../../../utils/isDefined';
import tempUserAction from '../actions/tempUserAction';

const MoreActionsMenu = ({
  children,
  client = {},
  openShowHistory,
  openDeleteClient
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onHistoryClick = useCallback(
    () => {
      openShowHistory({
        id: client.id,
        itemType: 'Client',
        titleText: client.name,
        itemTypeKey: 'CLIENT',
        historyObject: client
      });
      handleClose();
    },
    // eslint-disable-next-line
    [client.id]
  );

  const onDeleteClientClick = useCallback(
    () => {
      openDeleteClient({ client });
      handleClose();
    },
    // eslint-disable-next-line
    [client]
  );

  const onTempUserActionClick = useCallback(
    async action => {
      await tempUserAction(client.id, action);
      handleClose();
    },
    // eslint-disable-next-line
    [client.id]
  );

  return (
    <div>
      {cloneElement(children, { onClick: handleClick })}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem onClick={onDeleteClientClick}>
          {translate('CLIENTS.DELETE_CLIENT')}
        </MenuItem>
        <MenuItem onClick={onHistoryClick}>
          {translate('TOOLTIP.SHOW_HISTORY')}
        </MenuItem>
        {isDefined(client.connect30_domain) && (
          <MenuItem onClick={() => onTempUserActionClick('create')}>
            {translate('TOOLTIP.CREATE_TEMP_USER')}
          </MenuItem>
        )}
        {/* /*disabled for now as there is no easy way to check if temp user is already created
        <MenuItem  onClick={() => onTempUserActionClick('delete')}>
          {translate('TOOLTIP.DELETE_TEMP_USER')}
        </MenuItem> */}
      </Menu>
    </div>
  );
};

const mapDispatchToProps = {
  openShowHistory: showHistoryReduxActions.openShowHistory,
  openDeleteClient
};

export default flowRight(
  withUserCheck,
  connect(null, mapDispatchToProps)
)(MoreActionsMenu);
