import { combineReducers } from 'redux';

import { reducer as auth } from '../pages/auth/reducers';
import { reducer as simCards } from '../pages/simCards/reducers';
import { reducer as editSimcard } from '../modals/editSimcard/reducers';
import { reducer as swapSimcard } from '../modals/swapSimcard/reducers';
import { reducer as orderNewSimcard } from '../modals/orderNewSimcard/reducers';
import { reducer as users } from '../pages/users/reducers';
import { reducer as telephonyUsers } from '../pages/telephonyUsers/reducers';
import { reducer as requestNewUser } from '../modals/requestNewUser/reducers';
import { reducer as requestUserWizard } from '../modals/requestUserWizard/reducers';
import { reducer as changePass } from '../modals/changePass/reducers';
import { reducer as forgottenPass } from '../modals/forgottenPass/reducers';
import { reducer as editUser } from '../modals/editUser/reducers';
import { reducer as user } from '../pages/user/reducers';
import { reducer as clients } from '../pages/clients/reducers';
import { reducer as editClient } from '../modals/editClient/reducers';
import { reducer as roles } from '../pages/roles/reducers';
import { reducer as editRole } from '../modals/editRole/reducers';
import { reducer as queues } from '../pages/queues/reducers';
import { reducer as editQueue } from '../modals/editQueue/reducers';
import { reducer as schedules } from '../pages/schedules/reducers';
import { reducer as editSchedule } from '../modals/editSchedule/reducers';
import { reducer as queueStats } from '../modals/queueStats/reducers';
import { reducer as dataLimits } from '../pages/dataLimits/reducers';
import { reducer as dataLimitsUsage } from '../pages/datalimitsUsage/reducers';
import { reducer as showDatalimit } from '../modals/showDatalimit/reducers';
import { reducer as editDatalimit } from '../modals/editDatalimit/reducers';
import { reducer as partners } from '../pages/partners/reducers';
import { reducer as externalNoRanges } from '../pages/externalNumberRange/reducers';
import { reducer as editRange } from '../modals/editRange/reducers';
import { reducer as numberRangesMultiedit } from '../modals/numberRangeMultiedit/reducers';
import addresses from '../pages/addresses/reducers';
import { reducer as permissions } from '../pages/permissions/reducers';
import { reducer as addPermission } from '../modals/addPermission/reducers';
import { reducer as selectClient } from '../partials/selectClient/reducers';
import { reducer as detailView } from '../partials/detailView/reducers';
import { reducer as errorDialog } from '../partials/errorDialog/reducers';
import { reducer as loader } from '../partials/loader/reducers';
import { reducer as datalimitStats } from '../partials/dataLImitsStats/reducers';
import { reducer as datalimitMultiedit } from '../modals/datalimitMultiedit/reducers';
import { reducer as builder } from '../../_metronic/ducks/builder';
import { reducer as appSettings } from '../../app/pages/appSettings/reducers';
import { reducer as showHistory } from '../../app/modals/showHistory/reducers';
import { reducer as checkNumber } from '../../app/pages/checkNumber/reducers';
import { reducer as userContacts } from '../../app/pages/userContacts/reducers';
import { reducer as addExternalContact } from '../../app/modals/addExternalContact/reducers';
import { reducer as rightPanel } from '../partials/rightPanel/reducers';
import { reducer as invoices } from '../pages/invoices/reducers';
import { reducer as invoiceDetails } from '../modals/invoiceDetails/reducers';
import { reducer as dashboard } from '../pages/clientDashboard/reducers';
import { reducer as groups } from '../pages/groups/reducers';
import { reducer as subscriptions } from '../pages/subscriptions/reducers';
import { reducer as errorsPanel } from '../partials/errorsPanel/reducers';
import { reducer as editCustomJob } from '../pages/editCustomJobs/reducers';
import { reducer as licenses } from '../pages/licenses/reducers';
import { reducer as connections } from '../pages/connections/reducers';
import { reducer as activationMessageDialog } from '../partials/activationMessageDialog/reducers';
import { reducer as phonebookRegistration } from '../pages/phoneBookRegistration/reducers';
import { reducer as testUserSync } from '../modals/testUsersSync/reducers';
import { reducer as vacations } from '../pages/vacations/reducers';
import { reducer as editVacation } from '../modals/editVacation/reducers';
import { reducer as addingSchedules } from '../modals/addingSchedules/reducers';
import { reducer as iotProducts } from '../pages/simCardsIoT/reducers';
import { reducer as datalimitLogs } from '../modals/datalimitLogs/reducers';
import { reducer as cdrHooks } from '../pages/cdrHooks/reducers';
import { reducer as editCdrHook } from '../modals/editCdrHook/reducers';
import { reducer as accounts } from '../pages/accounts/reducers';
import { reducer as orders } from '../pages/orders/reducers';
import { reducer as terminateUser } from '../modals/terminateUser/reducers';
import { reducer as deleteClient } from '../modals/deleteClient/reducers';

const root = combineReducers({
  simCards,
  editSimcard,
  swapSimcard,
  orderNewSimcard,
  users,
  requestNewUser,
  requestUserWizard,
  telephonyUsers,
  changePass,
  forgottenPass,
  editUser,
  user,
  clients,
  partners,
  externalNoRanges,
  editRange,
  numberRangesMultiedit,
  addresses,
  roles,
  editRole,
  permissions,
  addPermission,
  editClient,
  auth,
  queues,
  editQueue,
  schedules,
  editSchedule,
  queueStats,
  dataLimits,
  dataLimitsUsage,
  showDatalimit,
  datalimitMultiedit,
  editDatalimit,
  selectClient,
  detailView,
  errorDialog,
  loader,
  builder,
  datalimitStats,
  appSettings,
  showHistory,
  checkNumber,
  userContacts,
  addExternalContact,
  rightPanel,
  invoices,
  invoiceDetails,
  dashboard,
  groups,
  subscriptions,
  errorsPanel,
  editCustomJob,
  licenses,
  connections,
  activationMessageDialog,
  phonebookRegistration,
  testUserSync,
  vacations,
  editVacation,
  addingSchedules,
  iotProducts,
  datalimitLogs,
  cdrHooks,
  editCdrHook,
  orders,
  accounts,
  terminateUser,
  deleteClient
});

export default root;
