import { Button, withStyles } from '@material-ui/core';
import React, { useMemo } from 'react';
import flowRight from 'lodash.flowright';

import styles from '../styles/roamingInfo';
import { translate } from '../../../i18n/I18nProvider';
import withSettings from '../../../utils/withSettings';
import { CONST_VALUES } from '../../../consts/initialValues';
import { connect } from 'react-redux';
import { isDefined, isUndefined } from '../../../utils/isDefined';
import { toAbsoluteUrl } from '../../../../_metronic';
import DEFAULT_FRONTEND_SETTINGS from '../../../consts/frontendSettings';
import Optional from '../../../utils/optional';

const RoamingInfo = ({
  classes,
  settings,
  selectedNumber,
  userSimcards,
  isPartnerClient
}) => {
  const isTDC = useMemo(() => {
    const selectedCard = userSimcards.find(
      card => card.msisdn === selectedNumber.value
    );
    if (isUndefined(selectedCard)) {
      return false;
    }

    return selectedCard.mobile_network?.brand === 'TDC' ? true : false;
  }, [userSimcards, selectedNumber]);

  return isTDC && !isPartnerClient ? (
    <div className={classes.root}>
      <header className={classes.title}>{translate('ROAMING_INFO')}</header>
      <div className={classes.contentContainer}>
        <Button
          href={CONST_VALUES.COUNTRYZONES}
          target="blank"
          style={{
            backgroundColor: '#FFF',
            color: '#000'
          }}
        >
          <div
            className={classes.buttonLabel}
            style={{
              border: `1px solid ${Optional(settings)
                .map(settings => settings.colors.aside_menu)
                .or(DEFAULT_FRONTEND_SETTINGS.colors.aside_menu)}`
            }}
          >
            <img
              src={toAbsoluteUrl('/media/icons/earth-europe.svg')}
              alt="Earth Logo"
              className={classes.icon}
              style={{ color: settings.colors.dashboard_icon }}
            ></img>{' '}
            {translate('COUNTRYZONES')}
          </div>
        </Button>
        <Button
          href={CONST_VALUES.PRICE_LIST}
          target="blank"
          style={{
            backgroundColor: '#FFF',
            color: '#000'
          }}
        >
          <div
            className={classes.buttonLabel}
            style={{
              border: `1px solid ${Optional(settings)
                .map(settings => settings.colors.aside_menu)
                .or(DEFAULT_FRONTEND_SETTINGS.colors.aside_menu)}`
            }}
          >
            <img
              src={toAbsoluteUrl('/media/icons/money-bill-1.svg')}
              alt="Earth Logo"
              className={classes.icon}
              style={{ color: settings.colors.dashboard_icon }}
            ></img>
            {translate('ROAMING_PRICES')}
          </div>
        </Button>
      </div>
    </div>
  ) : null;
};

const mapStatesToProps = ({ user, selectClient }) => {
  const { sim_cards: userSimcards = [], selectedNumber = {} } = user;

  const { selectedClient = {} } = selectClient;

  return {
    selectedNumber,
    userSimcards,
    isPartnerClient:
      isDefined(selectedClient?.partner_id) || isDefined(selectedClient?.client)
  };
};

export default flowRight(
  withSettings,
  connect(mapStatesToProps),
  withStyles(styles)
)(RoamingInfo);
