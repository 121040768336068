import * as yup from 'yup';
import { translate } from '../../../i18n/I18nProvider';

export default function() {
  return yup.object().shape({
    name: yup
      .string()
      .matches(
        /^[a-z\u00c0-\u017e][a-z0-9\u00c0-\u017e]{0,24}$/g,
        translate('AUTH.VALIDATION.INVALID_FIELD')
      )
      .required(translate('AUTH.VALIDATION.REQUIRED_FIELD')),
    vip_status: yup
      .number()
      .required(translate('AUTH.VALIDATION.REQUIRED_FIELD')),
    csbc_routing: yup
      .string()
      .required(translate('AUTH.VALIDATION.REQUIRED_FIELD')),
    connect30_domain: yup
      .string()
      .when(['csbc_routing'], {
        is: csbc_routing =>
          csbc_routing === 'mts' ||
          csbc_routing === 'mtp' ||
          csbc_routing === 'ipsolutions',
        then: yup.string().required(translate('AUTH.VALIDATION.REQUIRED_FIELD'))
      })
      .nullable()
  });
}
