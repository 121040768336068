const styles = theme => {
  return {
    root: { backgroundColor: '#FFFFFF' },
    dialogContent: {
      display: 'flex'
    },
    errorContainer: { margin: '5px', textAlign: 'center', color: '#C95454' },
    divider: { width: '1px', margin: '0 10px', backgroundColor: '#A9A9A9' },
    footer: {
      display: 'flex',
      justifyContent: 'center',
      padding: '10px 0',
      margin: '20px 0',
      '& button': {
        marginRight: '10px',
        [theme.breakpoints.down('xs')]: {
          marginTop: '10px'
        }
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'center'
      }
    }
  };
};

export default styles;
