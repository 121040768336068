import { withStyles } from '@material-ui/core';
import React from 'react';
import flowRight from 'lodash.flowright';

import styles from '../styles/priceLinks';
import withSettings from '../../../utils/withSettings';
import { translate } from '../../../i18n/I18nProvider';
import { CONST_VALUES } from '../../../consts/initialValues';
import { toAbsoluteUrl } from '../../../../_metronic';

const PriceLinks = ({ classes, settings }) => {
  return (
    <div className={classes.root}>
      <a
        className={classes.button}
        style={{
          backgroundColor: settings.colors.dashboard_button,
          color: settings.colors.dashboard_text
        }}
        href={CONST_VALUES.COUNTRYZONES}
        target="blank"
      >
        <img
          src={toAbsoluteUrl('/media/icons/earth-europe.svg')}
          alt="Earth Logo"
          className={classes.icon}
          style={{ color: settings.colors.dashboard_icon }}
        ></img>
        <div className={classes.label}> {translate('COUNTRYZONES')}</div>
      </a>
      <a
        className={classes.button}
        style={{
          backgroundColor: settings.colors.dashboard_button,
          color: settings.colors.dashboard_text
        }}
        href={CONST_VALUES.PRICE_LIST}
        target="blank"
      >
        <img
          src={toAbsoluteUrl('/media/icons/money-bill-1.svg')}
          alt="Earth Logo"
          className={classes.icon}
          style={{ color: settings.colors.dashboard_icon }}
        ></img>
        <div className={classes.label}>
          {`TDC ${translate('ROAMING_PRICES')}`}
        </div>
      </a>
    </div>
  );
};

export default flowRight(withSettings, withStyles(styles))(PriceLinks);
