import React, { useEffect, useMemo, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import { injectIntl } from 'react-intl';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';

import styles from './styles';
import SimcardInfo from './components/SimcardInfo';
import ActionsButtons from './components/ActionsButtons';
import SimcardMenu from './components/SimcardMenu';
import clsx from 'clsx';
import withSettings from '../../utils/withSettings';
import withUserCheck from '../../utils/withUserCheck';
import getSimcard from './actions/getSimcard';
import { isDefined, isObject } from '../../utils/isDefined';
import { actions as userReduxActions } from '../../pages/user/reducers';
import RefreshButton from '../tableCustomButtons/RefreshButton';

const SimcardsData = ({
  classes,
  intl,
  selectedNumber,
  simcards,
  settings,
  isNonClientAdminUser,
  setSimcardReload,
  simcardReload
}) => {
  const [data, setData] = useState({});
  useEffect(() => {
    (async () => {
      const simcard = simcards.find(
        simcard => simcard.msisdn === selectedNumber.value
      );

      if (isDefined(simcard?.id)) {
        const simCardResponse = await getSimcard({
          id: simcard?.id
        });

        setData(simCardResponse);
      }
    })();
  }, [selectedNumber.value, simcards]);

  useEffect(() => {
    (async () => {
      if (simcardReload) {
        if (isDefined(selectedNumber?.value)) {
          const simCardResponse = await getSimcard({
            number: selectedNumber.value
          });

          setData(simCardResponse);
        }

        setSimcardReload(false);
      }
    })();
    // eslint-disable-next-line
  }, [simcardReload]);

  const showData = useMemo(() => {
    return isObject(data?.simcard) && Object.keys(data?.simcard).length > 0;
  }, [data.simcard]);

  return (
    <div className={classes.root}>
      <header className={classes.title}>
        {intl.formatMessage({
          id: 'NUMBER.SIMCARD'
        })}
        <div className={classes.titleActions}>
          <RefreshButton onClick={() => setSimcardReload(true)} />
          {isNonClientAdminUser && isDefined(data?.simcard) && (
            <SimcardMenu simcard={data?.simcard}>
              <i
                className={clsx('fas fa-ellipsis-h', classes.dotsMenuIcon)}
                style={{ color: settings.colors.primary }}
              ></i>
            </SimcardMenu>
          )}
        </div>
      </header>
      <div className={classes.container}>
        {showData ? (
          <SimcardInfo
            simCardData={data?.simcard}
            isESimCard={data?.isESimCard}
            ESimStatus={data?.ESimStatus}
          />
        ) : (
          <div className={classes.textContainer}>
            <div>No SIM card details found</div>
          </div>
        )}
        {showData && <ActionsButtons data={data} />}
      </div>
    </div>
  );
};

const mapStatesToProps = ({ user }) => {
  const { sim_cards = [], selectedNumber, simcardReload } = user;

  return {
    selectedNumber,
    simcards: sim_cards,
    simcardReload
  };
};

const mapDispatchToProps = {
  setSimcardReload: userReduxActions.setSimcardReload
};

export default flowRight(
  injectIntl,
  withSettings,
  withUserCheck,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(SimcardsData);
