const styles = theme => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '350px',
      margin: '10px'
    },
    subscriptionRoot: {
      paddingLeft: '10px'
    },
    dataContainer: {
      display: 'flex',
      width: '100%',
      padding: '0 10px',
      justifyContent: 'space-between'
    },
    label: { width: '110px', display: 'flex', alignItems: 'center' },
    outporting: { display: 'flex', alignItems: 'center' },
    value: { fontWeight: 600, textAlign: 'end' },
    sectionLabel: { fontWeight: 600, fontSize: '14px', margin: '10px 0' },
    radioButtons: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-around',
      flexDirection: 'row'
    },
    checkboxRow: { display: 'flex', alignItems: 'center' },
    infoIcon: { marginLeft: '5px' },
    datacardInfo: { fontWeight: '500' },
    subContainer: { marginBottom: '10px' },
    dividingLine: { width: '100%', backgroundColor: '#A9A9A9', height: '1px' },
    featureRoot: { paddingRight: '10px' }
  };
};

export default styles;
