import React from 'react';
import { withStyles } from '@material-ui/styles';

import styles from '../styles/generalSection';
import Optional from '../../../utils/optional';
import DataBox from '../../../partials/dataBox/DataBox';
import { translate } from '../../../i18n/I18nProvider';
import DataBoxHashed from '../../../partials/dataBox/DataBoxHashed';

export function GeneralSection({ classes, field }) {
  const { value } = field;

  const showPIN = Optional(value?.mobile_network?.brand)
    .map(brand => brand === 'TDC')
    .or(false);

  return (
    <div className={classes.root}>
      <div className={classes.column}>
        <DataBox label="ICC" value={value.icc} width={180} />
        <DataBox label="MSISDN" value={value.msisdn} width={180} />
      </div>
      <div className={classes.column}>
        <DataBoxHashed
          label="PIN 1"
          value={showPIN ? value.pin1 : ''}
          width={120}
          infoIcon={showPIN ? false : true}
          infoIconText={showPIN ? '' : translate('TOOLTIP.SIMCARD_PIN_INFO')}
          shouldDataBeHashed={showPIN}
        />
        <DataBoxHashed
          label="PUK 1"
          value={showPIN ? value.puk1 : ''}
          width={120}
          infoIcon={showPIN ? false : true}
          infoIconText={showPIN ? '' : translate('TOOLTIP.SIMCARD_PIN_INFO')}
          shouldDataBeHashed={showPIN}
        />
      </div>
    </div>
  );
}

export default withStyles(styles)(GeneralSection);
