import { withStyles } from '@material-ui/styles';
import React, { useMemo } from 'react';
import clsx from 'clsx';

import styles from '../styles/features';
import { isDefined, isString, isUndefined } from '../../../utils/isDefined';
import DataRow from '../../../partials/Datarow/DataRow';
import StatusCell from '../../../partials/statusCell/StatusCell';
import pickOptionsForStatusCell from '../../../partials/statusCell/actions/pickOptionsForStatusCell';
import STATUS_BOX_OPTIONS from '../../../consts/statusBoxOptions';
import { translate } from '../../../i18n/I18nProvider';

const Features = ({ features = [], classes, customClasses }) => {
  const mappedFeatures = mapFeatures({ features });

  const customDataRowClasses = useMemo(() => {
    let dataRowClasses = {
      root: classes.dataRow_root,
      value: classes.dataRow_value
    };

    if (isDefined(customClasses)) {
      dataRowClasses = {
        root: clsx(classes.dataRow_root, customClasses.root),
        value: classes.dataRow_value
      };
    }

    return dataRowClasses;
  }, [classes, customClasses]);

  return (
    <div className={classes.root}>
      {mappedFeatures.map(feature => (
        <DataRow
          key={feature.label}
          labelText={feature.label}
          value={
            feature.isBoolean ? (
              <StatusCell
                isTable={false}
                {...pickOptionsForStatusCell(
                  STATUS_BOX_OPTIONS.ENABLED,
                  feature.value
                )}
              />
            ) : (
              feature.value
            )
          }
          customClasses={customDataRowClasses}
          dense
        />
      ))}
    </div>
  );
};

export default withStyles(styles)(Features);

export const mapFeatures = ({ features }) => {
  return features.reduce((arr, feature) => {
    switch (feature.key) {
      case 'watch-esim': {
        if (isUndefined(feature?.featureAttributes)) {
          return arr;
        }

        const attributes = feature?.featureAttributes;

        const enabledObject = attributes.find(attr => attr.key === 'enabled');

        if (isUndefined(enabledObject)) {
          return arr;
        }

        return [
          ...arr,
          {
            label: feature.name,
            value: checkValue(enabledObject.value, false),
            isBoolean: true
          }
        ];
      }
      case 'addons': {
        let addons = [];
        if (isUndefined(feature?.featureAttributes)) {
          return arr;
        }

        const attributes = feature?.featureAttributes;

        const extraSimObject = attributes.find(attr => attr.key === 'EXTRASIM');

        if (isDefined(extraSimObject)) {
          const mappedValue = checkStringValue(
            extraSimObject.value,
            translate('NONE')
          );

          const value = isString(mappedValue)
            ? mappedValue.toLowerCase()
            : null;

          addons = [
            ...addons,
            {
              label: extraSimObject.name,
              value
            }
          ];
        }

        return [...arr, ...addons];
      }
      default:
        return arr;
    }
  }, []);
};

const checkStringValue = (value, disabledOption) => {
  if (isUndefined(value) || value === false) {
    return void 0;
  }

  if (isDefined(disabledOption) && value === 'DISABLED_OPTION') {
    return disabledOption;
  }

  return value;
};

const checkValue = value => {
  if (isUndefined(value) || value === false || value === 'DISABLED_OPTION') {
    return false;
  }

  return true;
};
