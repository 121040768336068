import React, { useMemo } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';

import styles from '../styles/checkboxSection';
import { isDefined } from '../../../utils/isDefined';
import { translate } from '../../../i18n/I18nProvider';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';
import DataCheckboxRow from '../../../partials/dataBox/DataCheckboxRow';
import withUserCheck from '../../../utils/withUserCheck';
import { connect } from 'react-redux';

const CheckboxSection = ({
  classes,
  form,
  clientId,
  isPartnerOrDstnyAdmin,
  isTDC
}) => {
  const { initialValues } = form;

  const disableRechargeCheckbox = useMemo(() => {
    return (
      (isDefined(initialValues.recharge_count) &&
        initialValues.recharge_count > 0) ||
      !can(
        'update',
        createSubject('DataLimit', {
          client_id: Number(clientId)
        }),
        'allow_recharge'
      )
    );
  }, [initialValues.recharge_count, clientId]);

  return (
    isTDC && (
      <div className={classes.root}>
        <div>
          <DataCheckboxRow
            label={translate('DATA_LIMITS.AUTO_RECHARGE')}
            name="allow_recharge"
            disabled={disableRechargeCheckbox}
            infoIcon={true}
            checkBoxTooltip={
              disableRechargeCheckbox
                ? translate('DATA_LIMITS.TOOLTIP_DISABLED_RECHARGE')
                : void 0
            }
            infoIconTooltipComponent={
              <div>
                <div>{translate('DATA_LIMITS.TOOLTIP.ACTIVATION')}</div>
                <div style={{ height: '5px' }}></div>
                <div>{translate('DATA_LIMITS.TOOLTIP.AUTO_RECHARGE_1')}</div>
                <div style={{ height: '10px' }}></div>
                <div>{translate('DATA_LIMITS.TOOLTIP.DEACTIVATION')}</div>
                <div style={{ height: '5px' }}></div>
                <div>{translate('DATA_LIMITS.TOOLTIP.AUTO_RECHARGE_2')}</div>
              </div>
            }
            {...form}
          />
          <DataCheckboxRow
            label={translate('DATA_LIMITS.ROAMING_DISABLED')}
            name="roaming_disabled"
            disabled={
              !can(
                'update',
                createSubject('DataLimit', {
                  client_id: Number(clientId)
                }),
                'roaming_disabled'
              )
            }
            {...form}
          />
          <DataCheckboxRow
            label={translate('DATA_LIMITS.BLOCK_DATA_USAGE')}
            name="manual_block"
            infoIcon={true}
            infoIconText={translate('DATA_LIMITS.BLOCK_MANUALY')}
            disabled={
              !can(
                'update',
                createSubject('DataLimit', {
                  client_id: Number(clientId)
                }),
                'manual_block'
              )
            }
            {...form}
          />
        </div>

        {isPartnerOrDstnyAdmin && (
          <div>
            <DataCheckboxRow
              label={translate('DATA_LIMITS.TEMP_OPEN')}
              name="tmp_open"
              infoIcon={true}
              infoIconText={translate('DATA_LIMITS.TEMPORARY_OPEN_TOOLTIP')}
              disabled={
                !can(
                  'update',
                  createSubject('DataLimit', {
                    client_id: Number(clientId)
                  }),
                  'tmp_open'
                )
              }
              {...form}
            />
          </div>
        )}
      </div>
    )
  );
};

const mapStatesToProps = ({ editDatalimit }) => {
  return {
    isTDC: editDatalimit.isTDC
  };
};

export default flowRight(
  withUserCheck,
  connect(mapStatesToProps),
  withStyles(styles)
)(CheckboxSection);
