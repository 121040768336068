import {
  isArray,
  isDefined,
  isObject,
  isStringDefined,
  isUndefined
} from '../../../utils/isDefined';
import Optional from '../../../utils/optional';
import RESPONSE_CODES from '../../../consts/responseCodes';

const mapSwapErrorResponse = response => {
  if (isUndefined(response)) {
    return '';
  }

  if (response.status === RESPONSE_CODES.BAD_REQUEST) {
    if (!isArray(response.data) || response.data.length === 0) {
      return '';
    }

    return response.data.join(', ');
  }

  if (response.status === RESPONSE_CODES.INTERNAL_ERROR) {
    if (!isArray(response.data) || response.data.length === 0) {
      return '';
    }

    return response.data
      .map(el => el.message)
      .filter(el => isDefined(el) && isStringDefined(el))
      .join(', ');
  }

  if (response.status === RESPONSE_CODES.UNPROCESSABLE_ENTITY) {
    if (!isArray(response.data) || response.data.length === 0) {
      return '';
    }

    return Optional(response.data[0]?.problems)
      .map(problems => {
        if (!isArray(problems)) {
          return '';
        }

        return problems
          .map(problem => {
            if (!isObject(problem) || isUndefined(problem?.message)) {
              return void 0;
            }

            return problem.message;
          })
          .filter(el => isDefined(el))
          .join(', ');
      })
      .or('');
  }

  return '';
};

export default mapSwapErrorResponse;
