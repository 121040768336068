import React, { useCallback } from 'react';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';

import styles from '../styles';
import LockNumber from '../../lockNumber/LockNumber';
import { doesArrayHasLength, isDefined } from '../../../utils/isDefined';
import ShowQrCode from '../../showQrCode/ShowQrCode';
import { translate } from '../../../i18n/I18nProvider';
import withUserCheck from '../../../utils/withUserCheck';
import Optional from '../../../utils/optional';
import { actions as swapSimcardReduxActions } from '../../../modals/swapSimcard/reducers';
import createSubject from '../../../utils/createSubject';
import can from '../../../utils/can';
import CustomColorButton from '../../customButtons/CustomColorButton';
import withSettings from '../../../utils/withSettings';

export function ActionsButtons({
  classes,
  data = {},
  hasClientAccounts,
  openSwapSimcard,
  isLoading,
  problems,
  settings
}) {
  const {
    activationCode,
    simcard,
    isESimCard = false,
    isIoTCard = false
  } = data;

  const onSwapClick = useCallback(
    () => {
      const { id, msisdn, mobile_network, client_id } = simcard;
      openSwapSimcard({
        simcard: { id, msisdn, mobile_network, client_id, isESimCard },
        problems
      });
    },
    // eslint-disable-next-line
    [simcard, problems]
  );

  return (
    <div className={classes.buttonsContainer}>
      {hasClientAccounts &&
        simcard?.mobile_network?.brand === 'TDC' &&
        isESimCard &&
        isDefined(activationCode) && (
          <div className={classes.buttonContainer}>
            <ShowQrCode activationCode={activationCode} number={simcard.msisdn}>
              <CustomColorButton
                color={settings.colors.aside_menu_text}
                backgroundColor={settings.colors.aside_menu}
              >
                {translate('SWAP_SIMCARD.SHOW_QR_CODE')}
              </CustomColorButton>
            </ShowQrCode>
          </div>
        )}
      {simcard?.mobile_network?.brand === 'TDC' &&
        can('swap', createSubject('SimCard', simcard)) && (
          <>
            <CustomColorButton
              color={settings.colors.aside_menu_text}
              backgroundColor={settings.colors.aside_menu}
              onClick={onSwapClick}
              disabled={isLoading || isIoTCard}
              tooltipText={isIoTCard ? translate('TOOLTIP.IOT_SWAP_INFO') : ''}
            >
              {translate('BUTTON.SWAP_SIMCARD')}
            </CustomColorButton>
          </>
        )}
      <div className={classes.buttonContainer}>
        {simcard?.mobile_network?.brand === 'TDC' && (
          <LockNumber data={simcard} />
        )}
      </div>
    </div>
  );
}
const mapStatesToProps = ({ selectClient, loader, user }) => {
  return {
    hasClientAccounts: Optional(
      selectClient.selectedClient?.metadata?.account_ids
    )
      .map(accounts => doesArrayHasLength(accounts))
      .or(false),
    isLoading: loader.isLinearVisible,
    problems: user.subscription?.problems
  };
};

const mapDispatchToProps = {
  openSwapSimcard: swapSimcardReduxActions.openDialog
};

export default flowRight(
  injectIntl,
  withSettings,
  withUserCheck,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(ActionsButtons);
