import { URLS } from '../../../consts/endpoints';
import { deleteData } from '../../../utils/http';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { actions as clientsReduxActions } from '../../../pages/clients/reducers';
import store from '../../../store/store';

const deleteClient = async ({ clientId }) => {
  const response = await deleteData({
    url: `${URLS.CLIENTS}/${clientId}`,
    successSnackbar: { intlTextKey: 'CLIENT.DELETE_SUCCESS' }
  });

  if (response.status === RESPONSE_CODES.SUCCESS) {
    store.dispatch(clientsReduxActions.setRefresh(true));
    return true;
  }
};

export default deleteClient;
