import { withStyles } from '@material-ui/styles';
import { flowRight } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import clsx from 'clsx';

import styles from '../styles/generalData';
import Optional from '../../../utils/optional';
import { isDefined } from '../../../utils/isDefined';
import withSettings from '../../../utils/withSettings';
import CustomTitle from './CustomTitle';
import STATUSES from '../../../consts/statusBoxOptions';
import {
  NUMBER_LOOKUP_SIMCARD_DATA,
  TRUE_FALSE_OPTIONS
} from '../../../consts/initialValues';
import { mapValue } from '../actions/dataActions';
import { actions as editSimcardReduxActions } from '../../../modals/editSimcard/reducers';
import checkNumberData from '../../../utils/checkNumberData';
import { actions as rightPanelReduxActions } from '../../../partials/rightPanel/reducers';
import history from '../../../../history';
import { actions as selectClientReduxActions } from '../../../partials/selectClient/reducers';
import SimCardMenu from './SimCardMenu';
import changeClient from '../../../utils/changeClient';
import CopyToClipbaordTooltip from '../../../partials/copyToClipbaordTooltip/CopyToClipbaordTooltip';
import ExtraCards from './ExtraCards';

function SimcardData({
  classes,
  simcardData = {},
  settings,
  openEditSimcard,
  isDialog,
  fromRightPanel,
  rightPanelData = {},
  closeRightPanel,
  intl,
  setSelectedClient
}) {
  const { isDataExist, data } = useMemo(() => {
    return checkNumberData({
      numberData: simcardData,
      rightPanelData,
      fromRightPanel
    });
  }, [simcardData, rightPanelData, fromRightPanel]);

  const { client, owner, mobile_network, client_id, primary_card } = data;

  const onIccClick = useCallback(
    number => {
      if (isDefined(number)) {
        openEditSimcard({
          number,
          clientId: client_id,
          fromLookup: true,
          fromRightPanel
        });
      } else {
        openEditSimcard({
          simcard: data,
          simcardId: data.id,
          clientId: client_id,
          fromLookup: true,
          fromRightPanel
        });
      }
    },
    // eslint-disable-next-line
    [data]
  );

  const closeRightPanelAction = useCallback(
    () => {
      if (fromRightPanel) {
        closeRightPanel();
      }
    },
    // eslint-disable-next-line
    [fromRightPanel]
  );

  const onOwnerClick = async () => {
    closeRightPanelAction();
    return await changeClient({ client });
  };

  const onClientClick = async () => {
    closeRightPanelAction();

    await changeClient({ client });

    history.push(`/${client.id}/simCards`);
  };

  return (
    <div className={classes.root}>
      <CustomTitle
        title="NUMBER.SIMCARD_DATA"
        isDataExist={isDataExist}
        dotsMenu={true}
        menuComponent={
          <SimCardMenu simcard={data} fromRightPanel={fromRightPanel}>
            <i className={clsx('fas fa-ellipsis-h', classes.dotsIcon)} />
          </SimCardMenu>
        }
      >
        {isDataExist &&
          NUMBER_LOOKUP_SIMCARD_DATA.map((el, i) => {
            const value = mapValue(data[el.value]);
            const label = Optional(el.intlKey)
              .map(intlKey =>
                intl.formatMessage({
                  id: intlKey
                })
              )
              .or(el.value);

            switch (el.value) {
              case 'client': {
                return (
                  <div
                    className={classes.dataContainer}
                    key={i}
                    style={{
                      backgroundColor: i % 2 === 0 ? '#FFF' : '#FAFAFA'
                    }}
                  >
                    <div className={classes.dataKey}>{label}</div>
                    <div
                      className={classes.valueContainer}
                      style={{
                        color: settings.colors.links,
                        cursor: 'pointer'
                      }}
                      onClick={onClientClick}
                    >
                      <CopyToClipbaordTooltip
                        key={i}
                        value={Optional(client?.name).or('')}
                      >
                        {Optional(client?.name).or('')}
                      </CopyToClipbaordTooltip>
                    </div>
                    {/* </Link> */}
                  </div>
                );
              }
              case 'partner': {
                return (
                  <div
                    className={classes.dataContainer}
                    key={i}
                    style={{
                      backgroundColor: i % 2 === 0 ? '#FFF' : '#FAFAFA'
                    }}
                  >
                    <div className={classes.dataKey}>{label}</div>
                    <div className={classes.valueContainer}>
                      {Optional(client?.partner?.name).or('')}
                    </div>
                  </div>
                );
              }
              case 'owner': {
                return (
                  <div
                    className={classes.dataContainer}
                    key={i}
                    style={{
                      backgroundColor: i % 2 === 0 ? '#FFF' : '#FAFAFA'
                    }}
                  >
                    <div className={classes.dataKey}>{label}</div>
                    {isDefined(owner) && isDefined(client) ? (
                      isDefined(owner?.connect30_user_id) ? (
                        <Link
                          onClick={onOwnerClick}
                          className={classes.links}
                          style={{ color: settings.colors.links }}
                          to={{
                            pathname: `/${client.id}/telephonyUsers/user`,
                            state: { id: owner.connect30_user_id }
                          }}
                        >
                          <div className={classes.valueContainer}>
                            <CopyToClipbaordTooltip
                              value={Optional(owner?.name).or('')}
                            >
                              {Optional(owner?.name).or('')}
                            </CopyToClipbaordTooltip>
                          </div>
                        </Link>
                      ) : (
                        <Link
                          className={classes.links}
                          onClick={onOwnerClick}
                          style={{ color: settings.colors.links }}
                          to={{
                            pathname: `/${client.id}/users/user`,
                            state: { id: owner.id }
                          }}
                          key={i}
                        >
                          <div className={classes.valueContainer}>
                            <CopyToClipbaordTooltip
                              value={Optional(owner?.name).or('')}
                            >
                              {Optional(owner?.name).or('')}
                            </CopyToClipbaordTooltip>
                          </div>
                        </Link>
                      )
                    ) : (
                      <div className={classes.valueContainer}>
                        {Optional(owner?.name).or('')}
                      </div>
                    )}
                  </div>
                );
              }
              case 'icc': {
                return (
                  <div
                    className={classes.dataContainer}
                    key={i}
                    style={{
                      backgroundColor: i % 2 === 0 ? '#FFF' : '#FAFAFA'
                    }}
                  >
                    <div className={classes.dataKey}>{label}</div>
                    {isDialog ? (
                      <CopyToClipbaordTooltip value={value}>
                        <div>{value}</div>
                      </CopyToClipbaordTooltip>
                    ) : (
                      <div className={classes.dataContainer}>
                        <div
                          className={classes.links}
                          style={{ color: settings.colors.links }}
                          onClick={() => onIccClick()}
                        >
                          <CopyToClipbaordTooltip value={value}>
                            {value}
                          </CopyToClipbaordTooltip>
                        </div>
                      </div>
                    )}
                  </div>
                );
              }
              case 'mobile_network': {
                return (
                  <div
                    className={classes.dataContainer}
                    key={i}
                    style={{
                      backgroundColor: i % 2 === 0 ? '#FFF' : '#FAFAFA'
                    }}
                  >
                    <div className={classes.dataKey}>{label}</div>

                    <div>{Optional(mobile_network?.brand).or('')}</div>
                  </div>
                );
              }
              case 'active': {
                return (
                  <div
                    className={classes.dataContainer}
                    key={i}
                    style={{
                      backgroundColor: i % 2 === 0 ? '#FFF' : '#FAFAFA'
                    }}
                  >
                    <div className={classes.dataKey}>{label}</div>

                    <div>
                      {Optional(STATUSES.SIMCARD_ACTIVE[value]?.intlKey)
                        .map(key =>
                          intl.formatMessage({
                            id: key
                          })
                        )
                        .or(value)}
                    </div>
                  </div>
                );
              }
              case 'card_type': {
                return (
                  <div
                    className={classes.dataContainer}
                    key={i}
                    style={{
                      backgroundColor: i % 2 === 0 ? '#FFF' : '#FAFAFA'
                    }}
                  >
                    <div className={classes.dataKey}>{label}</div>

                    <div>
                      {Optional(STATUSES.SIMCARD_TYPE[value]?.intlKey)
                        .map(key =>
                          intl.formatMessage({
                            id: key
                          })
                        )
                        .or(value)}
                    </div>
                  </div>
                );
              }
              case 'data_card': {
                return (
                  <div
                    className={classes.dataContainer}
                    key={i}
                    style={{
                      backgroundColor: i % 2 === 0 ? '#FFF' : '#FAFAFA'
                    }}
                  >
                    <div className={classes.dataKey}>{label}</div>

                    <div>
                      {Optional(TRUE_FALSE_OPTIONS[value])
                        .map(key =>
                          intl.formatMessage({
                            id: key
                          })
                        )
                        .or(value)}
                    </div>
                  </div>
                );
              }
              case 'primary_card': {
                return (
                  <div
                    className={classes.dataContainer}
                    key={i}
                    style={{
                      backgroundColor: i % 2 === 0 ? '#FFF' : '#FAFAFA'
                    }}
                  >
                    <div className={classes.dataKey}>{label}</div>

                    <div>{Optional(primary_card?.msisdn).or('')}</div>
                  </div>
                );
              }
              default:
                return (
                  <div
                    className={classes.dataContainer}
                    key={i}
                    style={{
                      backgroundColor: i % 2 === 0 ? '#FFF' : '#FAFAFA'
                    }}
                  >
                    <div className={classes.dataKey}>{label}</div>

                    <div>{value}</div>
                  </div>
                );
            }
          })}
        <ExtraCards onIccClick={onIccClick} />
      </CustomTitle>
    </div>
  );
}

const mapStatesToProps = ({ checkNumber, rightPanel }) => {
  return {
    isDialog: checkNumber.isDialog,
    rightPanelData: rightPanel.number?.simcardData,
    simcardData: checkNumber.simcardData
  };
};

const mapDispatchToProps = {
  openEditSimcard: editSimcardReduxActions.openEditSimcard,
  closeRightPanel: rightPanelReduxActions.closeRightPanel,
  setSelectedClient: selectClientReduxActions.setSelectedClient
};

export default flowRight(
  injectIntl,
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(SimcardData);
