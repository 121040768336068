import { JSONAPI_URLS } from '../../../consts/endpoints';
import getCount from '../../../pages/clientDashboard/actions/getCount';
import { actions as reduxActions } from '../reducers';

const openDeleteClient = ({ client }) => {
  return async dispatch => {
    const usersCount = await getCount({
      url: JSONAPI_URLS.USERS,
      selectedClientId: client.id
    });

    const numberRangesCount = await getCount({
      url: JSONAPI_URLS.EXTERNAL_NO_RANGES,
      selectedClientId: client.id
    });

    const canDelete = usersCount === 0 && numberRangesCount === 0;

    dispatch(
      reduxActions.openDeleteClient({
        client,
        usersCount,
        numberRangesCount,
        canDelete
      })
    );
  };
};

export default openDeleteClient;
