import { withStyles } from '@material-ui/styles';
import React from 'react';
import flowRight from 'lodash.flowright';

import styles from '../styles/subscriptionStatus';
import withSettings from '../../../utils/withSettings';
import { mapFeatures } from '../../user/components/Features';
import { translate } from '../../../i18n/I18nProvider';

const Features = ({ features = [], classes }) => {
  const mappedFeatures = mapFeatures({ features, travelData: true });

  return mappedFeatures.map((feature, i) => {
    const { value, label, isBoolean } = feature;

    return (
      <div
        className={classes.dataContainer}
        key={i}
        style={{ backgroundColor: i % 2 === 0 ? '#FAFAFA' : '#FFF' }}
      >
        <div className={classes.dataKey}>{label}</div>
        <div>
          {isBoolean
            ? value
              ? translate('ENABLED')
              : translate('DISABLED')
            : value}
        </div>
      </div>
    );
  });
};

export default flowRight(withSettings, withStyles(styles))(Features);
