import React, { useState, cloneElement } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';

import withUserCheck from '../../../utils/withUserCheck';
import { actions as editSimcardReduxActions } from '../../../modals/editSimcard/reducers';
import { translate } from '../../../i18n/I18nProvider';
import { actions as activationMessageDialogReduxActions } from '../../../partials/activationMessageDialog/reducers';
import getUser from '../../../modals/terminateUser/actions/getUser';
import can from '../../../utils/can';
import createSubject from '../../../utils/createSubject';

const MoreActionsMenu = ({
  children,
  openActivationMessageDialog,
  user,
  openTerminateUser,
  connectClientId,
  clientId,
  onEditClick
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onActivationMessageClick = () => {
    openActivationMessageDialog(user);
    handleClose();
  };

  const onTerminateUser = () => {
    openTerminateUser({
      user,
      connectClientId,
      clientId
    });
    handleClose();
  };

  return (
    <div>
      {cloneElement(children, { onClick: handleClick })}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {can('update', createSubject('User', user)) && (
          <MenuItem onClick={onEditClick}>
            {translate('TOOLTIP.EDIT_USER')}
          </MenuItem>
        )}
        <MenuItem onClick={onActivationMessageClick}>
          {translate('TOOLTIP.ACTIVATION_MESSAGE')}
        </MenuItem>
        {can('delete', createSubject('User', user)) && (
          <MenuItem onClick={onTerminateUser}>
            {translate('TERMINATE_USER')}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

const mapStatesToProps = ({ selectClient }) => {
  return {
    connectClientId: selectClient.selectedClient.connect30_domain,
    clientId: selectClient.selectedClient.id
  };
};

const mapDispatchToProps = {
  openEditSimcard: editSimcardReduxActions.openEditSimcard,
  openActivationMessageDialog: activationMessageDialogReduxActions.openDialog,
  openTerminateUser: getUser
};

export default flowRight(
  withUserCheck,
  connect(mapStatesToProps, mapDispatchToProps)
)(MoreActionsMenu);
