import React, { useEffect } from 'react';
import { CircularProgress, Dialog, DialogContent } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';

import { actions as reduxActions } from './reducers';
import styles from './styles';
import { Formik, Form, Field } from 'formik';
import GeneralSection from './components/GeneralSection';
import SelectsSection from './components/SelectsSection';
import Description from './components/Description';
import ActionsButtons from './components/ActionsButtons';
import changeValuesForFormik from '../../utils/changeValuesForFormik';
import { updateOnSubmit } from './actions/updateSimcard';
import { isDefined } from '../../utils/isDefined';
import DatacardActions from './components/DatacardActions';
import { injectIntl } from 'react-intl';
import CustomDialogTitle from '../../partials/customDialogTitle/CustomDialogTitle';
import withUserCheck from '../../utils/withUserCheck';
import DraggableDialog from '../../partials/DraggableDialog';
import { setSelectsOptions } from './actions/getSelectsOptions';
import { getSimcard } from './actions/getSimcard';
import SubscriptionData from './components/SubscriptionData';
import RefreshButton from '../../partials/tableCustomButtons/RefreshButton';

export function EditSimcard({
  classes,
  open,
  closeEditSimcard,
  simcard,
  updateOnSubmit,
  clientId,
  intl,
  setSelectsOptions,
  isSuperAdmin: enableFieldForSuperAdmin,
  fromLookup,
  fromUserView,
  fromRightPanel,
  getSimcard,
  simcardId,
  refresh,
  setRefresh,
  isIoTCard,
  isLoading,
  number
}) {
  useEffect(() => {
    if (open) {
      setSelectsOptions(clientId);
      if (isDefined(number)) {
        getSimcard(null, number);
      } else if (isDefined(simcardId) && simcard.active) {
        getSimcard(simcardId, null, isIoTCard);
      }
    }
    // eslint-disable-next-line
  }, [clientId, open, number]);

  useEffect(() => {
    (async () => {
      if (refresh) {
        await getSimcard(null, simcard.msisdn, isIoTCard);

        setRefresh(false);
      }
    })();
    // eslint-disable-next-line
  }, [simcard, refresh]);

  if (!isDefined(simcard) || !Object.keys(simcard).length) {
    return null;
  }

  return (
    <Dialog
      maxWidth="lg"
      classes={{
        paper: classes.dialogRoot
      }}
      PaperComponent={DraggableDialog}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          closeEditSimcard();
        }
      }}
    >
      <CustomDialogTitle onCloseClick={closeEditSimcard}>
        <div className={classes.title}>
          {intl.formatMessage({
            id: 'EDIT.EDIT_SIMCARD'
          })}
          <RefreshButton onClick={() => setRefresh(true)} />
        </div>
      </CustomDialogTitle>
      {isLoading && (
        <div className={classes.loaderOverlay}>
          <CircularProgress />
        </div>
      )}
      <DialogContent className={classes.dialogContent}>
        <SubscriptionData />
        <Formik
          initialValues={changeValuesForFormik({
            values: simcard,
            fromNull: true
          })}
          enableReinitialize
          onSubmit={values =>
            updateOnSubmit({
              simcard: changeValuesForFormik({ values }),
              initialUserId: simcard.user_id,
              fromLookup,
              fromUserView,
              fromRightPanel
            })
          }
        >
          {({ handleSubmit, dirty }) => {
            return (
              <Form className={classes.content} onSubmit={handleSubmit}>
                <Field component={GeneralSection} />
                <Field
                  component={DatacardActions}
                  isCheckboxDisabled={!enableFieldForSuperAdmin}
                  simcard={simcard}
                />
                <div className={classes.lastRowContainer}>
                  <Field component={SelectsSection} />
                  <Field component={Description} name="description" />
                </div>
                <footer className={classes.footer}>
                  <ActionsButtons
                    onCloseClick={closeEditSimcard}
                    handleSubmit={handleSubmit}
                    dirty={dirty}
                  />
                </footer>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

const mapStatesToProps = ({ editSimcard, loader }) => {
  return {
    ...editSimcard,
    isLoading: loader.isLinearVisible
  };
};

const mapDispatchToProps = {
  closeEditSimcard: reduxActions.closeEditSimcard,
  setRefresh: reduxActions.setRefresh,
  updateOnSubmit,
  setSelectsOptions,
  getSimcard
};

export default flowRight(
  injectIntl,
  withUserCheck,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(EditSimcard);
