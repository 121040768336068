import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Button, Tooltip } from '@material-ui/core';

import styles from './styles/successButton';
import Optional from '../../utils/optional';

const CustomColorButton = ({
  children,
  onClick,
  classes,
  color,
  backgroundColor,
  disabled = false,
  tooltipText = void 0
}) => {
  return (
    <Tooltip title={Optional(tooltipText).or('')}>
      <div>
        <Button
          className={classes.root}
          style={{
            backgroundColor: !disabled ? backgroundColor : '#E0E0E0',
            color
          }}
          onClick={onClick}
          disabled={disabled}
        >
          {children}
        </Button>
      </div>
    </Tooltip>
  );
};

export default withStyles(styles)(CustomColorButton);
