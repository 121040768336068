import { connect } from 'react-redux';
import React from 'react';
import flowRight from 'lodash.flowright';

import styles from '../styles';
import { withStyles } from '@material-ui/styles';
import { translate } from '../../../i18n/I18nProvider';

const DeleteClientInfo = ({ usersCount, numberRangesCount, classes }) => {
  return (
    <div>
      <div>{translate('CLIENTS.DELETE_CLIENT_INFO')}</div>
      {usersCount > 0 && (
        <div className={classes.container}>
          <div className={classes.label}>{`${translate(
            'CLIENTS.ATTACHED_USERS'
          )} : `}</div>
          <div className={classes.value}>{usersCount}</div>
        </div>
      )}
      {numberRangesCount > 0 && (
        <div className={classes.container}>
          <div className={classes.label}>{`${translate(
            'CLIENTS.ATTACHED_RANGES'
          )} : `}</div>
          <div className={classes.value}>{numberRangesCount}</div>
        </div>
      )}
    </div>
  );
};

const mapStatesToProps = ({ deleteClient }) => {
  return {
    ...deleteClient
  };
};

export default flowRight(
  connect(mapStatesToProps),
  withStyles(styles)
)(DeleteClientInfo);
