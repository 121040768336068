import { formatDateForSchedule } from '../../../utils/formatDate';
import { isDefined, isStringDefined } from '../../../utils/isDefined';

const mapRangesToUpdate = ({ ranges = [], formValues = {}, checkboxes }) => {
  const mappedFormValues = mapFormValues(formValues, checkboxes);
  const mappedRanges = mapRanges({ ranges, mappedFormValues });

  return mapRangesForRequest(mappedRanges);
};

export default mapRangesToUpdate;

const mapFormValues = (formValues = {}, checkboxes) => {
  const valuesKeys = Object.keys(formValues);
  return valuesKeys.reduce((obj, valueKey) => {
    if (checkboxes[valueKey]) {
      if (valueKey === 'och_inactive_at') {
        return { ...obj, [valueKey]: null };
      }

      return { ...obj, [valueKey]: '' };
    }
    if (valueKey === 'och_active_at' || valueKey === 'och_inactive_at') {
      const date = formatDateForSchedule(formValues[valueKey]);

      if (isStringDefined(date)) {
        return { ...obj, [valueKey]: `${date}T00:00:00` };
      }

      return obj;
    }

    if (isStringDefined(formValues[valueKey])) {
      return { ...obj, [valueKey]: formValues[valueKey] };
    }
    return obj;
  }, {});
};

const mapRanges = ({ ranges, mappedFormValues }) => {
  const mappedFormsKeys = Object.keys(mappedFormValues);
  const mappedRanges = ranges.map(range => {
    const mappedRange = { relationships: {}, attributes: {} };

    mappedFormsKeys.map(key => {
      if (key === 'network') {
        if (range.provider?.id !== mappedFormValues[key]) {
          mappedRange.relationships.provider = {
            data: { type: 'providers', id: Number(mappedFormValues.network) }
          };
        }
        return key;
      }

      if (key === 'address') {
        return key;
      }

      if (mappedFormValues[key] !== range[key]) {
        mappedRange.attributes[key] = mappedFormValues[key];
      }

      return key;
    });

    if (Object.keys(mappedRange.relationships).length === 0) {
      delete mappedRange.relationships;
    }

    if (Object.keys(mappedRange.attributes).length === 0) {
      delete mappedRange.attributes;
    }

    if (Object.keys(mappedRange).length === 0) {
      return null;
    }

    return { ...mappedRange, id: range.id };
  });

  return mappedRanges.filter(range => isDefined(range));
};

const mapRangesForRequest = ranges => {
  return ranges.map(range => {
    return {
      ...range,
      id: Number(range.id),
      type: 'external_number_ranges'
    };
  });
};
