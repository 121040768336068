import React from 'react';
import { isArray, isDefined } from '../../../utils/isDefined';
import removeWhiteSpaces from '../../../utils/removeWhiteSpaces';
import searchInArray from '../../../utils/searchInArray';
import EnabledCell from './EnabledCell';
import searchNumbers from '../../../utils/searchNumbers';

const tableColumns = () => {
  const columns = [
    {
      title: 'Enabled',
      field: 'accountEnabled',
      render: rowData => <EnabledCell enabled={rowData.accountEnabled} />,
      untranslatedTitle: true,
      lookup: {
        true: 'Yes',
        false: 'No'
      }
    },
    {
      title: 'Display Name',
      field: 'displayName',
      untranslatedTitle: true
    },
    {
      title: 'Mail',
      field: 'mail',
      untranslatedTitle: true
    },
    {
      title: 'Job title',
      field: 'jobTitle',
      untranslatedTitle: true
    },
    {
      title: 'Mobile phone',
      field: 'mobilePhone',
      untranslatedTitle: true,
      customFilterAndSearch: (term, rowData) =>
        searchNumbers(rowData.mobilePhone, term)
    },
    {
      title: 'Business phones',
      field: 'businessPhones',
      untranslatedTitle: true,
      render: rowData =>
        isArray(rowData.businessPhones) ? rowData.businessPhones.join(',') : '',
      customFilterAndSearch: (term, rowData) =>
        searchInArray({
          array: isArray(rowData.businessPhones) ? rowData.businessPhones : [],
          term: removeWhiteSpaces(term),
          isValueNumber: true
        })
    },
    {
      title: 'Department',
      field: 'department',
      untranslatedTitle: true
    },
    {
      title: 'Office location',
      field: 'officeLocation',
      untranslatedTitle: true,
      customFilterAndSearch: (term, rowData) =>
        searchNumbers(rowData.officeLocation, term)
    },
    {
      title: 'User principal name',
      field: 'userPrincipalName',
      untranslatedTitle: true
    },
    {
      title: 'Country',
      field: 'country',
      untranslatedTitle: true,
      hidden: true
    },
    {
      title: 'State',
      field: 'state',
      untranslatedTitle: true,
      hidden: true
    },
    {
      title: 'City',
      field: 'city',
      untranslatedTitle: true,
      hidden: true
    },
    {
      title: 'Usage location',
      field: 'usageLocation',
      untranslatedTitle: true,
      hidden: true
    },
    {
      title: 'Employe Id',
      field: 'employeeId',
      untranslatedTitle: true,
      hidden: true
    },
    {
      title: 'Preferred Language',
      field: 'preferredLanguage',
      untranslatedTitle: true,
      hidden: true
    },
    {
      title: 'Manager name',
      field: 'manager',
      render: rowData =>
        isDefined(rowData.manager) ? rowData.manager.displayName : '',
      untranslatedTitle: true,
      hidden: true
    },
    {
      title: 'Manager mail',
      field: 'manager',
      render: rowData =>
        isDefined(rowData.manager) ? rowData.manager.mail : '',
      untranslatedTitle: true,
      hidden: true
    },
    {
      title: 'Microsoft uuid',
      field: 'id',
      untranslatedTitle: true,
      hidden: true
    }
  ];

  return columns.map((column, i) => {
    return { ...column, id: i };
  });
};

export default tableColumns;
