import { createReduxActionTypes } from '../../../utils/actionFactory';

const OPEN_TERMINATE_USER = 'OPEN_ETERMINATE_USER';
const RESET_TERMINATE_USER = 'RESET_TERMINATE_USER';
const UPDATE_VALUES = 'UPDATE_VALUES';

export const actionTypes = createReduxActionTypes('TERMINATE_USER', [
  OPEN_TERMINATE_USER,
  RESET_TERMINATE_USER,
  UPDATE_VALUES
]);

const DEFAULT_STATE = {
  open: false,
  isEdit: false,
  values: {},
  user: {}
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[OPEN_TERMINATE_USER]: {
      const { user, error, values } = action;
      return {
        ...state,
        open: true,
        user,
        error,
        values
      };
    }
    case actionTypes[UPDATE_VALUES]: {
      const { values } = action;
      return {
        ...state,
        open: true,
        values: { ...state.values, ...values }
      };
    }
    case actionTypes[RESET_TERMINATE_USER]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  openTerminateUser: ({ user, error, values }) => ({
    type: actionTypes[OPEN_TERMINATE_USER],
    user,
    error,
    values
  }),
  updateValues: values => ({
    type: actionTypes[UPDATE_VALUES],
    values
  }),
  closeTerminateUser: () => ({ type: actionTypes[RESET_TERMINATE_USER] })
};
