const styles = theme => {
  return {
    dialogContent: { margin: '10px 0' },
    footer: {
      display: 'flex',
      justifyContent: 'center',
      margin: '10px 0',
      '& button': {
        marginRight: '10px'
      }
    },
    container: { display: 'flex', margin: '10px 0' },
    label: { width: '200px' },
    value: { fontWeight: 600 }
  };
};

export default styles;
