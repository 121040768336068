import React from 'react';
import { connect } from 'react-redux';

import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import SecondaryButton from '../../../partials/customButtons/SecondaryButton';
import { translate } from '../../../i18n/I18nProvider';

const ActionsButtons = ({ onCloseClick, handleSubmit, canDelete }) => {
  return (
    <>
      <SecondaryButton onClick={onCloseClick}>
        {translate('BUTTON.CANCEL')}
      </SecondaryButton>
      {canDelete && (
        <PrimaryButton onClick={handleSubmit}>
          {translate('DELETE')}
        </PrimaryButton>
      )}
    </>
  );
};

const mapStatesToProps = ({ deleteClient }) => {
  return {
    canDelete: deleteClient.canDelete
  };
};

export default connect(mapStatesToProps)(ActionsButtons);
