import { Tooltip, withStyles } from '@material-ui/core';
import React from 'react';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';

import styles from '../styles/userData';
import Optional from '../../../utils/optional';
import { translate } from '../../../i18n/I18nProvider';
import clsx from 'clsx';
import { doesArrayHasLength } from '../../../utils/isDefined';

const UserData = ({ classes, user }) => {
  return (
    <div className={classes.root}>
      <div className={classes.sectionLabel}>{translate('EDIT.USER')}</div>
      <div className={classes.dataContainer}>
        <div className={classes.label}>
          {translate('REQUEST_NEW_USER.FIRST_NAME')}
        </div>
        <div className={classes.value}>{user?.name?.given_name}</div>
      </div>
      <div className={classes.dataContainer}>
        <div className={classes.label}>
          {translate('REQUEST_NEW_USER.LAST_NAME')}
        </div>
        <div className={classes.value}>{user?.name?.family_name}</div>
      </div>
      <div className={classes.dataContainer}>
        <div className={classes.label}>E-mail</div>
        <div className={classes.value}>{user?.email}</div>
      </div>
      <div className={classes.dataContainer}>
        <div className={classes.label}>
          <div>{translate('USER.LICENSE_TYPE')}</div>
          <Tooltip title={translate('TOOLTIP.LICENSES')}>
            <i className={clsx('fas fa-info-circle', classes.infoIcon)} />
          </Tooltip>
        </div>
        <div className={classes.value}>{user?.licenseType}</div>
      </div>
      <div className={classes.sectionLabel}>{translate('NUMBERS')}</div>
      <div className={classes.dataContainer}>
        <div className={classes.label}>
          <div>{translate('FIXED')}</div>
          <Tooltip title={translate('TOOLTIP.FIXED_NUMBER')}>
            <i className={clsx('fas fa-info-circle', classes.infoIcon)} />
          </Tooltip>
        </div>
        <div className={classes.value}>
          {Optional(user?.fixed)
            .map(fixed => fixed.join(', '))
            .or('')}
        </div>
      </div>
      <div
        className={classes.dataContainer}
        style={{ alignItems: 'flex-start' }}
      >
        <div className={classes.label}>{translate('MOBILE')}</div>
        <div className={classes.value}>
          <div>
            {Optional(user?.standardCards)
              .map(simcards =>
                simcards.map(card => (
                  <div key={card.id}>{`+${card.msisdn}`}</div>
                ))
              )
              .or('')}
          </div>
        </div>
      </div>
      {doesArrayHasLength(user?.datacards) && (
        <div
          className={classes.dataContainer}
          style={{ alignItems: 'flex-start' }}
        >
          <div className={classes.label}>{translate('NUMBER.DATACARD')}</div>
          <div className={classes.value}>
            <div>
              {user?.datacards.map(card => (
                <div key={card.id}>{`+${card.msisdn}`}</div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStatesToProps = ({ terminateUser }) => {
  return {
    user: terminateUser.user
  };
};

export default flowRight(
  connect(mapStatesToProps),
  withStyles(styles)
)(UserData);
