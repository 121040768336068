import React, { useEffect, useMemo, useState } from 'react';
import { CircularProgress, DialogActions, withStyles } from '@material-ui/core';
import flowRight from 'lodash.flowright';
import QRCode from 'qrcode';
import { connect } from 'react-redux';
import clsx from 'clsx';

import withSettings from '../../../utils/withSettings';
import styles from '../styles/orderESimcard';
import { translate } from '../../../i18n/I18nProvider';
import { isDefined, isStringEmpty } from '../../../utils/isDefined';
import createPdf from '../actions/createPdf';
import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import SecondaryButton from '../../../partials/customButtons/SecondaryButton';
import closeSwapCard from '../actions/closeSwapCard';
import { URLS } from '../../../consts/endpoints';
import { getData } from '../../../utils/http';
import { findESimStatus } from '../../../consts/initialValues';
import Optional from '../../../utils/optional';

const OrderSummary = ({
  settings,
  classes,
  activationResponse,
  closeSwapCard,
  simcard
}) => {
  const [src, setSrc] = useState('');
  const [hideCode, setHideCode] = useState(true);
  const [status, setStatus] = useState(void 0);
  const [transactionId, setTransactionId] = useState(null);
  const [isTransactionComplete, setIsTransactionComplete] = useState(false);

  const number = useMemo(() => simcard.msisdn, [simcard]);

  useEffect(() => {
    if (isDefined(activationResponse?.['ac_token'])) {
      QRCode.toDataURL(activationResponse['ac_token']).then(data => {
        setSrc(data);
      });
    }
    if (isDefined(activationResponse?.transaction_id)) {
      setTransactionId(activationResponse?.transaction_id);
    }
  }, [activationResponse]);

  useEffect(() => {
    const getStatus = async () => {
      try {
        const esimStatus = await getData(
          `${URLS.CARRIER}/cell_number/${number}/esim_status`
        );
        setStatus(esimStatus);

        if (isStringEmpty(src)) {
          if (isDefined(esimStatus?.['ac_token'])) {
            QRCode.toDataURL(esimStatus['ac_token']).then(data => {
              setSrc(data);
            });
          }
        }

        if (
          esimStatus?.state === 'CONFIRMED' ||
          esimStatus?.state === 'RELEASED'
        ) {
          setHideCode(false);
        } else {
          setHideCode(true);
        }
      } catch (error) {
        setStatus({ state: 'ERROR' });
        console.error('Error:', error);
      }
    };

    const getTransactionStatus = async () => {
      const transaction = await getData(
        `${URLS.QUOTE_CLIENTS}/${simcard.client_id}/transactions/${transactionId}`
      );

      if (transaction?.status === 'COMPLETED') {
        setIsTransactionComplete(true);
      }
    };

    const transactionInterval = setInterval(() => {
      if (isTransactionComplete === false && isDefined(transactionId)) {
        getTransactionStatus();
      }

      if (isTransactionComplete === true) {
        clearInterval(transactionInterval);
      }
    }, 5000);

    const statusInterval = setInterval(() => {
      if (isTransactionComplete === true && status?.state !== 'ENABLED') {
        getStatus();
      }
    }, 10000);

    return () => {
      clearInterval(statusInterval);
      clearInterval(transactionInterval);
    };

    // eslint-disable-next-line
  }, [number, status, isTransactionComplete, transactionId]);

  const onSaveClick = () => {
    return createPdf({ number, src });
  };

  return (
    <div className={classes.dialogContent}>
      <div className={classes.titleRow}>
        {translate('SWAP_SIMCARD.ESIM_SUMMARY_1')}
      </div>
      <div className={classes.row}>
        {`${translate('SWAP_SIMCARD.ESIM_SUMMARY_2')} ${number}`}
      </div>
      <div className={classes.row}>
        {translate('SWAP_SIMCARD.ESIM_SUMMARY_3')}
      </div>
      <div className={clsx(classes.row, classes.dialogContent)}>
        <div>{translate('SWAP_SIMCARD.ESIM_SUMMARY_4')} </div>
        <div>{translate('SWAP_SIMCARD.ESIM_SUMMARY_5')}</div>
      </div>

      <div className={clsx(classes.row, classes.bold)}>
        {translate('SWAP_SIMCARD.ESIM_SUMMARY_7')}
      </div>
      {isDefined(status) && (
        <div className={classes.boldRow}>{`${translate('CURRENT')} ${translate(
          'ESIM_STATUS'
        )} : ${Optional(findESimStatus(status?.state)).or('')}`}</div>
      )}
      {!status && (
        <div className={classes.loaderContainer}>
          <CircularProgress />
        </div>
      )}
      {!hideCode && (
        <div className={classes.qrCodeContainer}>
          <img src={src} alt="icon" className={classes.icon}></img>
          <div
            className={classes.row}
            style={{ color: settings.colors.modal_header }}
          >
            {`${translate('SWAP_SIMCARD.ESIM_SUMMARY_6')} ${number}`}
          </div>
        </div>
      )}
      <div className={classes.infoRow}>{translate('SWAP_SIMCARD.ESIM_5')}</div>

      <DialogActions className={classes.footer}>
        {!hideCode && (
          <PrimaryButton onClick={onSaveClick}>
            {translate('SWAP_SIMCARD.SAVE_PDF')}
          </PrimaryButton>
        )}

        <SecondaryButton onClick={closeSwapCard}>
          {translate('BUTTON.CLOSE')}
        </SecondaryButton>
      </DialogActions>
    </div>
  );
};

const mapStatesToProps = ({ swapSimcard }) => {
  return {
    ...swapSimcard
  };
};

const mapDispatchToProps = {
  closeSwapCard
};

export default flowRight(
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(OrderSummary);
