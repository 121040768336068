import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';
import { Formik, Form, Field } from 'formik';
import { Dialog, Tab, Tabs, DialogContent } from '@material-ui/core';
import { injectIntl } from 'react-intl';

import { actions as reduxActions } from './reducers';
import styles from './styles';
import InformationSection from './components/InformationSection';
import changeObjectValues from '../../utils/changeObjectValues';
import { updateOnSubmit } from './actions/updateClient';
import validateSchema from './actions/validateSchema';
import CustomDialogTitle from '../../partials/customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../../partials/DraggableDialog';
import TabPanel from '../../partials/TabPanel';
import ActionsSection from './components/ActionsSection';
import withUserCheck from '../../utils/withUserCheck';
import FormSection from './components/FormSection';
import { isDefined, isUndefined } from '../../utils/isDefined';

export function EditClient({
  resetClient,
  client,
  classes,
  selectsOptions,
  open,
  intl,
  updateOnSubmit,
  isSuperAdmin,
  role
}) {
  const [tabIndex, setTabIndex] = useState(0);
  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const onSubmit = useCallback(
    values => {
      updateOnSubmit(changeObjectValues(values, '', null), role);
      setTabIndex(0);
    },
    // eslint-disable-next-line
    [role]
  );

  const title = `${
    client.id
      ? intl.formatMessage({
          id: 'EDIT.EDIT'
        })
      : intl.formatMessage({
          id: 'EDIT.CREATE'
        })
  } ${intl.formatMessage({
    id: 'EDIT.CLIENT'
  })}`;

  const onClose = () => {
    setTabIndex(0);
    resetClient();
  };

  return (
    <Dialog
      maxWidth="lg"
      classes={{
        paper: classes.dialogContent
      }}
      PaperComponent={DraggableDialog}
      open={open}
      onClose={onClose}
    >
      <CustomDialogTitle onCloseClick={onClose}>{title}</CustomDialogTitle>

      <Tabs value={tabIndex} onChange={handleChange} centered>
        <Tab
          label={intl.formatMessage({
            id: 'EDIT.INFO'
          })}
        />
        {isDefined(client.id) && (
          <Tab
            disabled={isUndefined(client.id)}
            label={intl.formatMessage({
              id: 'TABLE.ACTIONS'
            })}
          />
        )}
        {isSuperAdmin && isDefined(client.id) && (
          <Tab
            disabled={isUndefined(client.id)}
            label={intl.formatMessage({
              id: 'SETTINGS'
            })}
          />
        )}
      </Tabs>
      <DialogContent>
        <TabPanel
          value={tabIndex}
          index={0}
          className={classes.contentContainer}
        >
          <Formik
            initialValues={changeObjectValues(client, null, '')}
            enableReinitialize
            onSubmit={onSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={validateSchema()}
          >
            {({ handleSubmit }) => {
              return (
                <Form className={classes.content} onSubmit={handleSubmit}>
                  <Field
                    component={InformationSection}
                    selectsOptions={selectsOptions}
                    clientId={client.id}
                  />
                </Form>
              );
            }}
          </Formik>
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <ActionsSection />
        </TabPanel>
        <TabPanel value={tabIndex} index={2}>
          <FormSection onCloseDialog={onClose} clientId={client.id} />
        </TabPanel>
      </DialogContent>
    </Dialog>
  );
}

const mapStatesToProps = ({ editClient, auth }) => {
  return {
    ...editClient,
    role: auth.role
  };
};

const mapDispatchToProps = {
  ...reduxActions,
  updateOnSubmit
};

export default flowRight(
  injectIntl,
  withUserCheck,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(EditClient);
