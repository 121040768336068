import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { actions as sagaActions, GET_SIMCARD } from './types';
import { actions as reduxActions } from '../reducers';
import { getData } from '../../../utils/http';
import { JSONAPI_URLS, URLS } from '../../../consts/endpoints';
import { isDefined } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';
import getSubscriptions from './getSubscription';
import urlCreator from '../../../utils/urlCreator';
import checkIfIsEsimCard from '../../../utils/checkIfIsEsimCard';
import checkIfIsIoT from '../../../utils/checkIfIsIoT';

export function getSimcard(id, number) {
  return {
    type: sagaActions[GET_SIMCARD],
    id,
    number
  };
}

export function* fetchSimcard({ id, number }) {
  let simcard = {};
  let activationCode = void 0;
  let isESimCard = false;
  let subscription = void 0;
  let isIoTCard = false;

  try {
    if (isDefined(number)) {
      const { data } = yield call(
        getData,
        urlCreator(JSONAPI_URLS.SIM_CARDS, {
          filter: { msisdn: number }
        })
      );

      if (isDefined(data[0])) {
        simcard = data[0];
      } else {
        simcard = {};
      }
    } else {
      const { data } = yield call(getData, `${URLS.SIM_CARDS}/${id}.json`);

      simcard = data;
    }

    if (isDefined(simcard?.icc) && isDefined(simcard?.client_id)) {
      const response = yield call(
        getData,
        `${URLS.QUOTE_CLIENTS}/${simcard.client_id}/resources/search?icc=${[
          simcard.icc
        ]}`
      );
      activationCode = Optional(response?.activationCode).or(void 0);

      isESimCard = checkIfIsEsimCard(response?.simCardType);

      isIoTCard = checkIfIsIoT(response?.usage_type);

      subscription = yield call(
        getSubscriptions,
        simcard.msisdn,
        simcard.client_id
      );
    }
  } finally {
    yield put(
      reduxActions.setSimcard({
        simcard,
        activationCode,
        isESimCard,
        subscription
      })
    );

    if (isIoTCard) {
      yield put(reduxActions.setIsIoT(true));
    }
  }
}

function* watchFetchSimcard() {
  yield takeLatest(sagaActions[GET_SIMCARD], fetchSimcard);
}

export default watchFetchSimcard();
