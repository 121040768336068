import React, { useCallback } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import flowRight from 'lodash.flowright';

import { actions as reduxActions } from './reducers';
import styles from './styles';
import CustomDialogTitle from '../../partials/customDialogTitle/CustomDialogTitle';
import DraggableDialog from '../../partials/DraggableDialog';
import { translate } from '../../i18n/I18nProvider';
import DeleteClientConfirmation from './components/DeleteClientConfirmation';
import DeleteClientInfo from './components/DeleteClientInfo';
import ActionsButtons from './components/ActionsButtons';
import deleteClient from './actions/deleteClient';

const DeleteClient = ({
  classes,
  open,
  closeDeleteClient,
  client,
  canDelete = false
}) => {
  const onDeleteClick = useCallback(async () => {
    const isRequestSuccess = await deleteClient({ clientId: client.id });

    if (isRequestSuccess) {
      closeDeleteClient();
    }
    // eslint-disable-next-line
  }, [client]);

  if (!open) {
    return null;
  }

  return (
    <Dialog
      maxWidth="lg"
      PaperComponent={DraggableDialog}
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          closeDeleteClient();
        }
      }}
    >
      <CustomDialogTitle onCloseClick={closeDeleteClient}>
        {`${translate('CLIENTS.DELETE_CLIENT')} : ${client?.name}`}
      </CustomDialogTitle>
      <DialogContent className={classes.dialogContent}>
        {canDelete ? (
          <DeleteClientConfirmation name={client.name} />
        ) : (
          <DeleteClientInfo />
        )}
      </DialogContent>
      <footer className={classes.footer}>
        <ActionsButtons
          onCloseClick={closeDeleteClient}
          handleSubmit={onDeleteClick}
        />
      </footer>
    </Dialog>
  );
};

const mapStatesToProps = ({ deleteClient }) => {
  return {
    ...deleteClient
  };
};

const mapDispatchToProps = {
  closeDeleteClient: reduxActions.closeDeleteClient
};

export default flowRight(
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(DeleteClient);
