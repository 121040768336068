import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';

import PrimaryButton from '../../../partials/customButtons/PrimaryButton';
import SecondaryButton from '../../../partials/customButtons/SecondaryButton';
import { translate } from '../../../i18n/I18nProvider';
import { actions as reduxActions } from '../reducers';
import { isDefined, isUndefined } from '../../../utils/isDefined';
import terminateUser from '../actions/terminateUser';

const ActionsButtons = ({
  closeDialog,
  values,
  user,
  error,
  connectClientId
}) => {
  const handleSubmit = useCallback(async () => {
    const response = await terminateUser({ values, user, connectClientId });

    if (response) {
      closeDialog();
    }
    // eslint-disable-next-line
  }, [values, user, connectClientId]);

  const shouldDisableButton = useMemo(() => {
    return (
      Object.keys(values)
        .map(el => {
          return (
            isUndefined(values[el].termination_date) &&
            values[el].after_termination_period === false
          );
        })
        .filter(el => el === true).length > 0
    );
  }, [values]);

  return (
    <>
      <PrimaryButton
        onClick={handleSubmit}
        disabled={shouldDisableButton || isDefined(error)}
      >
        {translate('TERMINATE_USER')}
      </PrimaryButton>
      <SecondaryButton onClick={closeDialog}>
        {translate('BUTTON.CANCEL')}
      </SecondaryButton>
    </>
  );
};

const mapStatesToProps = ({ terminateUser, selectClient }) => {
  const { user, values, error } = terminateUser;
  return {
    user,
    values,
    error,
    connectClientId: selectClient.selectedClient?.connect30_domain
  };
};

const mapDispatchToProps = {
  closeDialog: reduxActions.closeTerminateUser
};

export default connect(mapStatesToProps, mapDispatchToProps)(ActionsButtons);
