export default {
  SUCCESS: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,
  UNAUTHORIZED: [401],
  INTERNAL_ERROR: 500,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  UNPROCESSABLE_ENTITY: 422,
  MITEL_TO_PASS: [400, 500],
  BAD_REQUEST: 400,
  CONNECTION_TIMEOUT: 503
};

export const ERRORS_MESSAGE = {
  NETWORK_ERROR: 'Network Error'
};
