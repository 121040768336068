import { createReduxActionTypes } from '../../../utils/actionFactory';

const SET_EDIT_SIMCARD = 'SET_EDIT_SIMCARD';
const SET_SELECT_OPTIONS = 'SET_SELECT_OPTIONS';
const SET_ICC = 'SET_ICC';
const RESET_EDIT_SIMCARD = 'RESET_EDIT_SIMCARD';
const SET_REFRESH = 'SET_REFRESH';
const SET_SIMCARD = 'SET_SIMCARD';
const SET_IS_IOT = 'SET_IS_IOT';

export const actionTypes = createReduxActionTypes('EDIT_SIMCARD', [
  SET_EDIT_SIMCARD,
  SET_SELECT_OPTIONS,
  SET_ICC,
  SET_SIMCARD,
  SET_REFRESH,
  RESET_EDIT_SIMCARD,
  SET_IS_IOT
]);

const DEFAULT_STATE = {
  open: false,
  selectOptions: { users: [] },
  simcard: {},
  fromLookup: false,
  fromUserView: false,
  fromChangelog: false,
  simcardId: void 0,
  activationCode: void 0,
  isESimCard: false,
  subscription: {},
  refresh: false,
  isIoTCard: false
};

export const reducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case actionTypes[SET_EDIT_SIMCARD]: {
      const {
        simcard,
        clientId,
        fromLookup,
        fromUserView,
        fromRightPanel,
        simcardId,
        fromChangelog,
        number
      } = action;
      return {
        ...state,
        open: true,
        simcard: { ...simcard },
        clientId,
        fromLookup,
        fromUserView,
        fromRightPanel,
        simcardId,
        fromChangelog,
        number
      };
    }
    case actionTypes[SET_SELECT_OPTIONS]: {
      const { selectOptions } = action;
      return {
        ...state,
        selectOptions
      };
    }
    case actionTypes[SET_ICC]: {
      const { icc } = action;
      return {
        ...state,
        simcard: { ...state.simcard, icc }
      };
    }
    case actionTypes[SET_IS_IOT]: {
      const { isIoTCard } = action;
      return {
        ...state,
        isIoTCard
      };
    }
    case actionTypes[SET_REFRESH]: {
      const { refresh } = action;
      return {
        ...state,
        refresh
      };
    }
    case actionTypes[SET_SIMCARD]: {
      const { simcard, activationCode, isESimCard, subscription } = action;
      return {
        ...state,
        simcard,
        activationCode,
        isESimCard,
        subscription
      };
    }
    case actionTypes[RESET_EDIT_SIMCARD]: {
      return { ...DEFAULT_STATE };
    }
    default:
      return state;
  }
};

export const actions = {
  openEditSimcard: ({
    simcard,
    clientId,
    fromLookup,
    fromUserView,
    fromRightPanel,
    simcardId,
    fromChangelog,
    number
  }) => ({
    type: actionTypes[SET_EDIT_SIMCARD],
    simcard,
    clientId,
    fromLookup,
    fromUserView,
    fromRightPanel,
    simcardId,
    fromChangelog,
    number
  }),
  setSelectOptions: selectOptions => ({
    type: actionTypes[SET_SELECT_OPTIONS],
    selectOptions
  }),
  setSimcard: ({ simcard, activationCode, isESimCard, subscription }) => ({
    type: actionTypes[SET_SIMCARD],
    simcard,
    activationCode,
    isESimCard,
    subscription
  }),
  setIcc: icc => ({
    type: actionTypes[SET_ICC],
    icc
  }),
  setIsIoT: isIoTCard => ({
    type: actionTypes[SET_IS_IOT],
    isIoTCard
  }),
  setRefresh: refresh => ({
    type: actionTypes[SET_REFRESH],
    refresh
  }),
  closeEditSimcard: () => ({ type: actionTypes[RESET_EDIT_SIMCARD] })
};
