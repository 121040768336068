import React from 'react';
import flowRight from 'lodash.flowright';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Tooltip } from '@material-ui/core';

import styles from '../styles';
import withSettings from '../../../utils/withSettings';
import MoreActionsMenu from './MoreActionsMenu';
import { translate } from '../../../i18n/I18nProvider';

const ActionButtons = ({ subscription, classes, settings }) => {
  return (
    <div className={classes.actionButtons}>
      <MoreActionsMenu subscription={subscription}>
        <Tooltip title={translate('TOOLTIP.MORE_ACTION')}>
          <i
            className={clsx('fas fa-ellipsis-h', classes.actionIcon)}
            style={{
              color: settings.colors.links
            }}
          ></i>
        </Tooltip>
      </MoreActionsMenu>
    </div>
  );
};

export default flowRight(withSettings, withStyles(styles))(ActionButtons);
