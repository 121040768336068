const styles = () => {
  return {
    root: {
      overflow: 'visible'
    },
    datalimitsList: {
      display: 'flex',
      width: '100%',
      margin: '5px 0 10px 25px',
      alignItems: 'center'
    },
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: '16px',
      overflow: 'visible'
    },
    tooltip: { cursor: 'default' },
    infoIcon: {
      marginLeft: '10px'
    },
    tableOverlay: {
      position: 'absolute',
      top: '0px',
      left: '0px',
      width: '100%',
      height: '100%',
      background: '#FFF',
      opacity: '.5',
      zIndex: 100,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column'
    },
    labelRow: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
      fontSize: '12px',
      paddingRight: '5px'
    }
  };
};

export default styles;
