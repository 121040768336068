import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tooltip,
  withStyles
} from '@material-ui/core';
import React from 'react';
import flowRight from 'lodash.flowright';
import { connect } from 'react-redux';
import clsx from 'clsx';

import withSettings from '../../../utils/withSettings';
import styles from '../styles/userData';
import DatePicker from './DatePicker';
import { actions as reduxActions } from '../reducers';
import { doesArrayHasLength, isArray } from '../../../utils/isDefined';
import Optional from '../../../utils/optional';
import { translate } from '../../../i18n/I18nProvider';
import { formatDateForSchedule } from '../../../utils/formatDate';
import Features from './Features';

const Subscription = ({ settings, classes, user, updateValues, values }) => {
  const onRadioButtonChange = ({ e, key, id }) => {
    if (values[id][key] !== e.target.value) {
      updateValues({
        [id]: { ...values[id], [key]: e.target.value }
      });
    }
  };
  const onCheckboxChange = ({ e, key, id, additionalValue }) => {
    if (values[id][key] !== e.target.checked) {
      let mappedData = {
        [id]: { ...values[id], [key]: e.target.checked }
      };

      if (additionalValue) {
        mappedData = { [id]: { ...mappedData[id], ...additionalValue } };
      }

      updateValues({
        ...mappedData
      });
    }
  };

  const onDateChange = ({ date, id }) => {
    updateValues({
      [id]: {
        ...values[id],
        termination_date: formatDateForSchedule(date)
      }
    });
  };

  return (
    <div
      className={clsx(classes.root, classes.subscriptionRoot)}
      style={{
        width: doesArrayHasLength(user?.standardCardsWithSub)
          ? '360px'
          : '300px'
      }}
    >
      {doesArrayHasLength(user?.standardCardsWithSub) && (
        <>
          <div className={classes.sectionLabel}>
            {translate('REQUEST_NEW_USER.MOBILE_SUBSCRIPTION')}
          </div>
          {user?.standardCardsWithSub.map(card => (
            <div className={classes.subContainer} key={card.id}>
              <div className={classes.dataContainer}>
                <div
                  className={classes.label}
                  style={{ alignItems: 'flex-start', width: '90px' }}
                >
                  {translate('REQUEST_NEW_USER.SUBSCRIPTION')}
                </div>
                <div className={classes.value}>
                  {Optional(card?.sub?.skuTranslations?.default).or('')}
                </div>
              </div>
              <div className={classes.dataContainer}>
                <div
                  className={classes.label}
                  style={{ alignItems: 'flex-start', width: '90px' }}
                >
                  {translate('NAME')}
                </div>
                <div className={classes.value}>
                  {Optional(card?.sub?.name).or('')}
                </div>
              </div>
              {isArray(card?.sub?.features) && (
                <Features
                  features={card?.sub?.features}
                  customClasses={{ root: classes.featureRoot }}
                />
              )}
              <RadioGroup
                value={values[card?.sub?.id]?.action}
                onChange={e =>
                  onRadioButtonChange({
                    e,
                    key: 'action',
                    id: card?.sub?.id
                  })
                }
                className={classes.radioButtons}
              >
                <FormControlLabel
                  value="put_on_hold"
                  control={
                    <Radio
                      icon={
                        <i
                          className="far fa-circle"
                          style={{ color: settings.colors.modal_header }}
                        ></i>
                      }
                      checkedIcon={
                        <i
                          className="far fa-dot-circle"
                          style={{ color: settings.colors.modal_header }}
                        ></i>
                      }
                    />
                  }
                  label={
                    <div className={classes.label}>
                      <div>{translate('PUT_ON_HOLD')}</div>
                      <Tooltip title={translate('TOOLTIP.PUT_ON_HOLD')}>
                        <i
                          className={clsx(
                            'fas fa-info-circle',
                            classes.infoIcon
                          )}
                        />
                      </Tooltip>
                    </div>
                  }
                />
                <FormControlLabel
                  value="terminate"
                  control={
                    <Radio
                      icon={
                        <i
                          className="far fa-circle"
                          style={{ color: settings.colors.modal_header }}
                        ></i>
                      }
                      checkedIcon={
                        <i
                          className={'far fa-dot-circle'}
                          style={{ color: settings.colors.modal_header }}
                        ></i>
                      }
                    />
                  }
                  label={
                    <div className={classes.label}>
                      <div>{translate('TERMINATE')}</div>
                      <Tooltip title={translate('TOOLTIP.TERMINATE')}>
                        <i
                          className={clsx(
                            'fas fa-info-circle',
                            classes.infoIcon
                          )}
                        />
                      </Tooltip>
                    </div>
                  }
                />
              </RadioGroup>
              <div className={classes.checkboxRow}>
                <Checkbox
                  checked={values[card?.sub?.id]?.allow_outporting}
                  style={{ color: settings.colors.modal_header }}
                  onChange={e =>
                    onCheckboxChange({
                      e,
                      key: 'allow_outporting',
                      id: card?.sub?.id
                    })
                  }
                />
                <div className={classes.outporting}>
                  <div>{translate('TERMINATE_USER.ALLOW_OUTPORTING')}</div>
                  <Tooltip title={translate('TOOLTIP.OUTPORTING')}>
                    <i
                      className={clsx('fas fa-info-circle', classes.infoIcon)}
                    />
                  </Tooltip>
                </div>
              </div>
              <div className={classes.sectionLabel}>
                <div className={classes.outporting}>
                  <div>{translate('TERMINATE_USER.TERMINATION_DATE')}</div>
                  <Tooltip title={translate('TOOLTIP.DATE_PICKER')}>
                    <i
                      className={clsx('fas fa-info-circle', classes.infoIcon)}
                    />
                  </Tooltip>
                </div>
              </div>
              <DatePicker
                values={values[card?.sub?.id]}
                setValues={date => onDateChange({ date, id: card?.sub?.id })}
              />
              <div className={classes.checkboxRow}>
                <Checkbox
                  checked={values[card?.sub?.id]?.after_termination_period}
                  style={{ color: settings.colors.modal_header }}
                  onChange={e =>
                    onCheckboxChange({
                      e,
                      key: 'after_termination_period',
                      id: card?.sub?.id,
                      additionalValue: { termination_date: null }
                    })
                  }
                />
                <div>{translate('TERMINATE_USER.AFTER_TERMINATION')}</div>
              </div>
              {Object.keys(values).length > 1 && (
                <div className={classes.dividingLine}></div>
              )}
            </div>
          ))}
        </>
      )}
      {doesArrayHasLength(user?.datacardsCardsWithSub) && (
        <>
          <div className={classes.sectionLabel}>
            {translate('REQUEST_NEW_USER.DATA_SUBSCRIPTION')}
          </div>
          {user?.datacardsCardsWithSub.map(card => (
            <div className={classes.subContainer} key={card.id}>
              <div className={classes.dataContainer}>
                <div
                  className={classes.label}
                  style={{ alignItems: 'flex-start', width: '90px' }}
                >
                  {translate('REQUEST_NEW_USER.SUBSCRIPTION')}
                </div>
                <div className={classes.value}>
                  {Optional(card?.sub?.skuTranslations?.default).or('')}
                </div>
              </div>
              <div className={classes.dataContainer}>
                <div
                  className={classes.label}
                  style={{ alignItems: 'flex-start', width: '90px' }}
                >
                  {translate('NAME')}
                </div>
                <div className={classes.value}>
                  {Optional(card?.sub?.name).or('')}
                </div>
              </div>

              <div className={classes.datacardInfo}>
                {translate('TERMINATE_USER.DATACRAD_INFO')}
              </div>

              <div className={classes.sectionLabel}>
                <div className={classes.outporting}>
                  <div>{translate('TERMINATE_USER.TERMINATION_DATE')}</div>
                  <Tooltip title={translate('TOOLTIP.DATE_PICKER')}>
                    <i
                      className={clsx('fas fa-info-circle', classes.infoIcon)}
                    />
                  </Tooltip>
                </div>
              </div>
              <DatePicker
                values={values[card?.sub?.id]}
                setValues={date => onDateChange({ date, id: card?.sub?.id })}
              />
              <div className={classes.checkboxRow}>
                <Checkbox
                  checked={values[card?.sub?.id]?.after_termination_period}
                  style={{ color: settings.colors.modal_header }}
                  onChange={e =>
                    onCheckboxChange({
                      e,
                      key: 'after_termination_period',
                      id: card?.sub?.id,
                      additionalValue: { termination_date: null }
                    })
                  }
                />
                <div>{translate('TERMINATE_USER.AFTER_TERMINATION')}</div>
              </div>
              {user?.datacardsCardsWithSub.length > 1 && (
                <div className={classes.dividingLine}></div>
              )}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

const mapStatesToProps = ({ terminateUser }) => {
  const { user, values } = terminateUser;
  return {
    user,
    values
  };
};

const mapDispatchToProps = {
  updateValues: reduxActions.updateValues
};

export default flowRight(
  withSettings,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(Subscription);
