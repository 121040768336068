import React, { useEffect } from 'react';
import flowRight from 'lodash.flowright';
import { injectIntl } from 'react-intl';

import withDetailsView from '../../utils/withDetailsView';
import ClientsTable from './components/ClientsTable';
import getCustomJobs from '../../utils/getCustomJobs';
import DeleteClient from '../../modals/deleteClient/DeleteClient';

export function Clients() {
  useEffect(() => {
    (async () => {
      await getCustomJobs();
    })();
  }, []);
  return (
    <>
      <ClientsTable />
      <DeleteClient />
    </>
  );
}

export default flowRight(injectIntl, withDetailsView)(Clients);
