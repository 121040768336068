import { withStyles } from '@material-ui/core';
import flowRight from 'lodash.flowright';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import styles from '../styles/generalData';
import { isArray, isDefined, isUndefined } from '../../../utils/isDefined';
import withSettings from '../../../utils/withSettings';
import { translate } from '../../../i18n/I18nProvider';

const ExtraCards = ({ subscription, classes, settings, onIccClick }) => {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    if (isDefined(subscription)) {
      const data = mapSubscription(subscription);

      setCards(data);
    }
  }, [subscription]);

  return cards.map((card, i) => (
    <div key={i}>
      <div className={classes.bold}>{card.name}</div>
      <div
        className={classes.dataContainer}
        key={i}
        style={{
          backgroundColor: '#FFF'
        }}
      >
        <div className={classes.dataKey}>icc</div>
        <div className={classes.dataContainer}>
          <div
            className={classes.links}
            style={{ color: settings.colors.links }}
            onClick={() => onIccClick(card.number)}
          >
            {card.icc}
          </div>
        </div>
      </div>
      <div
        className={classes.dataContainer}
        style={{
          backgroundColor: '#FAFAFA'
        }}
      >
        <div className={classes.dataKey}>
          {translate('TABLE.MOBILE_NUMBER')}
        </div>

        <div>{card.number}</div>
      </div>
    </div>
  ));
};

const mapStatesToProps = ({ checkNumber }) => {
  const { subscription } = checkNumber;
  return {
    subscription
  };
};

export default flowRight(
  withSettings,
  connect(mapStatesToProps),
  withStyles(styles)
)(ExtraCards);

const mapSubscription = subscription => {
  const { features } = subscription;

  if (!isArray(features)) {
    return [];
  }

  const phoneAndSimcards = features.find(el => el.key === 'phone-and-sim-card');

  if (isUndefined(phoneAndSimcards)) {
    return [];
  }

  const { featureAttributes } = phoneAndSimcards;

  if (!isArray(featureAttributes)) {
    return [];
  }

  const extraCards = featureAttributes.reduce((obj, att) => {
    if (att.key.includes('resources-sim-cards-extra')) {
      const no = att.key.replace('resources-sim-cards-extra-', '');

      if (isDefined(obj[no])) {
        return { ...obj, [no]: { ...obj[no], icc: att.value, name: att.name } };
      } else {
        return { ...obj, [no]: { icc: att.value } };
      }
    }

    if (att.key.includes('resources-phone-numbers-extra')) {
      const no = att.key.replace('resources-phone-numbers-extra-', '');

      if (isDefined(obj[no])) {
        return { ...obj, [no]: { ...obj[no], number: att.value } };
      } else {
        return { ...obj, [no]: { number: att.value } };
      }
    }

    return obj;
  }, {});

  return Object.keys(extraCards)
    .map(el => {
      return { ...extraCards[el] };
    })
    .filter(card => isDefined(card.iccF) || isDefined(card.number));
};
