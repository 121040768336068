import React, { useEffect } from 'react';
import flowRight from 'lodash.flowright';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';

import { getUser } from './actions/getUser';
import UserDetails from './components/UserDetails';
import { actions as reduxActions } from './reducers';
import styles from './styles';
import SimcardsData from '../../partials/simcardsData/SimcardsData';
import { doesArrayHasLength, isUndefined } from '../../utils/isDefined';
import DataLimits from './components/DataLimits';
import withUserCheck from '../../utils/withUserCheck';
import SubscriptionData from './components/SubscriptionData';
import RoamingInfo from './components/RoamingInfo';

const User = ({
  location,
  getUser,
  resetUser,
  classes,
  userSimcards,
  hasCurrentUserConnectId,
  user,
  isNonClientAdminUser,
  isPartnerAdmin,
  isCurrentUserSelectedClientUser,
  connectClientId,
  currentUserClientId,
  currentLoggedUserId,
  refresh
}) => {
  useEffect(() => {
    resetUser();
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    getUser({
      location,
      hasCurrentUserConnectId,
      isNonClientAdminUser,
      isCurrentUserSelectedClientUser,
      connectClientId,
      currentUserClientId,
      isPartnerAdmin,
      currentLoggedUserId
    });
    return resetUser;
    // eslint-disable-next-line
  }, [
    location,
    hasCurrentUserConnectId,
    isNonClientAdminUser,
    isCurrentUserSelectedClientUser,
    connectClientId,
    isPartnerAdmin,
    currentLoggedUserId,
    refresh
  ]);

  if (isUndefined(user.id) && isUndefined(user.mitelData)) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div>
        <UserDetails hasCurrentUserConnectId={hasCurrentUserConnectId} />
      </div>
      {doesArrayHasLength(userSimcards) && (
        <div className={classes.numbersDataContainer}>
          <div>
            <DataLimits />
          </div>
          <div className={classes.columnContainer}>
            <SimcardsData />
            <SubscriptionData />
            <RoamingInfo />
          </div>
        </div>
      )}
    </div>
  );
};

const mapStatesToProps = ({ auth, user, selectClient }) => {
  const { sim_cards: userSimcards = [], refresh } = user;

  return {
    user,
    userSimcards,
    connectClientId: selectClient.selectedClient.connect30_domain,
    currentUserClientId: auth.defaultClient.connect30_domain,
    currentLoggedUserId: auth.user.id,
    refresh
  };
};

const mapDispatchToProps = {
  getUser,
  resetUser: reduxActions.resetUser
};

export default flowRight(
  withRouter,
  withUserCheck,
  connect(mapStatesToProps, mapDispatchToProps),
  withStyles(styles)
)(User);
