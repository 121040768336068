import React, { useCallback } from 'react';
import { withStyles } from '@material-ui/styles';
import flowRight from 'lodash.flowright';

import styles from '../styles';
import { setClient } from '../../../modals/editClient/actions/setClient';
import { connect } from 'react-redux';
import CustomIconButton from '../../../partials/tableCustomButtons/CustomIconButton';
import history from '../../../../history';
import changeClient from '../../../utils/changeClient';
import MoreActionsMenu from './MoreActionsMenu';
import { Tooltip } from '@material-ui/core';
import { translate } from '../../../i18n/I18nProvider';
import clsx from 'clsx';
import withSettings from '../../../utils/withSettings';

export function TableButtonsColumn({
  data: client,
  classes,
  openEditClient,
  settings
}) {
  const onEditClick = useCallback(
    () => {
      openEditClient(client);
    },
    // eslint-disable-next-line
    [client]
  );

  const onGoToClick = async () => {
    await changeClient({ client });

    history.push(`/${client.id}/dashboard`);
  };

  return (
    <div className={classes.actionButtons}>
      <CustomIconButton
        iconTooltipIntlKey="TOOLTIP.GO_TO_CLIENT"
        iconClassName="far fa-arrow-alt-circle-right"
        onClick={onGoToClick}
      />
      <CustomIconButton
        iconTooltipIntlKey="TOOLTIP.EDIT_CLIENT"
        onClick={onEditClick}
        iconClassName="far fa-edit"
      />
      <MoreActionsMenu client={client}>
        <Tooltip title={translate('TOOLTIP.MORE_ACTION')}>
          <i
            className={clsx('fas fa-ellipsis-h', classes.actionIcon)}
            style={{
              color: settings.colors.links
            }}
          ></i>
        </Tooltip>
      </MoreActionsMenu>
    </div>
  );
}

const mapDispatchToProps = {
  openEditClient: setClient
};

export default flowRight(
  withSettings,
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(TableButtonsColumn);
