import { isString } from './isDefined';
import Optional from './optional';

const checkIfIsIoT = usageType => {
  return Optional(usageType)
    .map(type => {
      if (!isString(type)) {
        return false;
      }

      return type.includes('iot');
    })
    .or(false);
};

export default checkIfIsIoT;
