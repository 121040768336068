import { Tooltip, withStyles } from '@material-ui/core';
import React, { useState } from 'react';

import styles from './styles';
import clsx from 'clsx';
import { translate } from '../../i18n/I18nProvider';

const DataBoxHashed = ({
  label,
  value,
  classes,
  width,
  infoIcon = false,
  infoIconText,
  shouldDataBeHashed = false
}) => {
  const [hashed, setHashed] = useState(shouldDataBeHashed);

  return (
    <div
      className={classes.root}
      style={{ minWidth: width ? `${width}px` : '260px' }}
    >
      <div className={classes.label}>
        {label}
        {infoIcon && (
          <Tooltip title={infoIconText}>
            <i className={clsx('fas fa-info-circle', classes.infoIcon)}></i>
          </Tooltip>
        )}
      </div>
      <Tooltip
        title={
          shouldDataBeHashed
            ? hashed
              ? `${translate('TOOLTIP.CLICK_TO_SEE')} ${label}`
              : `${translate('TOOLTIP.CLICK_TO_HIDE')} ${label}`
            : ''
        }
      >
        <div
          className={clsx(classes.value, {
            [classes.hashed]: shouldDataBeHashed
          })}
          onClick={() => (shouldDataBeHashed ? setHashed(!hashed) : () => {})}
        >
          {!hashed ? value : '#'.repeat(value.length)}
        </div>
      </Tooltip>
    </div>
  );
};

export default withStyles(styles)(DataBoxHashed);
