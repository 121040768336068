import { call, put } from 'redux-saga/effects';

import { takeLatest } from '../../../utils/reduxSaga';
import { URLS } from '../../../consts/endpoints';
import { actions as sagaActions, UPDATE_CLIENT } from './types';
import RESPONSE_CODES from '../../../consts/responseCodes';
import { updateData, postData } from '../../../utils/http';
import { actions as reduxActions } from '../reducers';
import { actions as clientsReduxActions } from '../../../pages/clients/reducers';
import { isDefined, isStringDefined } from '../../../utils/isDefined';
import showSnackbar from '../../../utils/showSnackbar';
import { getInitialValue } from '../../../partials/selectClient/actions';

export function updateOnSubmit(client, role) {
  return {
    type: sagaActions[UPDATE_CLIENT],
    client,
    role
  };
}

export function* updateClient({ client, role }) {
  const isClientUpdate = isDefined(client.id) ? true : false;
  const mappedData = mapDataToUpdate(client, isClientUpdate);

  const response = yield call(isClientUpdate ? updateData : postData, {
    url: isClientUpdate ? `${URLS.CLIENTS}/${client.id}` : URLS.CLIENTS,
    data: {
      data: mappedData
    }
  });

  if (response.status === RESPONSE_CODES.SUCCESS) {
    yield call(showSnackbar, {
      intlTextKey: 'MESSAGE.UPDATE.CLIENT',
      messageType: 'success',
      time: 3000
    });
  }

  if (response.status === RESPONSE_CODES.CREATED) {
    yield call(showSnackbar, {
      intlTextKey: 'MESSAGE.CREATED.CLIENT',
      messageType: 'success',
      time: 3000
    });
  }

  if (
    response.status === RESPONSE_CODES.CREATED ||
    response.status === RESPONSE_CODES.SUCCESS
  ) {
    yield call(getInitialValue, { role });

    yield put(clientsReduxActions.setRefresh(true));
    yield put(reduxActions.resetClient());
  }
}

function* watchUpdateClient() {
  yield takeLatest(sagaActions[UPDATE_CLIENT], updateClient);
}

export default watchUpdateClient();

function mapDataToUpdate(data, isClientUpdate) {
  const {
    id,
    name,
    partner_id,
    csbc_routing,
    connect30_domain,
    primary_contact,
    admin_contact,
    admin_email,
    admin_phone,
    comment,
    notes,
    mitel_prov_url,
    snom_prov_url,
    vip_status,
    display_name,
    metadata,
    default_address
  } = data;

  let mappedData = {
    type: 'clients',
    attributes: {
      name,
      partner_id,
      csbc_routing,
      connect30_domain,
      primary_contact,
      admin_contact,
      admin_email,
      admin_phone,
      comment,
      notes,
      mitel_prov_url,
      snom_prov_url,
      vip_status,
      metadata
    }
  };

  mappedData.attributes.metadata = { ...metadata, display_name };

  if (isClientUpdate) {
    mappedData = {
      id,
      ...mappedData
    };
  }

  if (isStringDefined(default_address)) {
    mappedData.attributes.primary_address_id = Number(default_address);
  }

  return mappedData;
}
